import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { GenerarNumAlbaranIdentificadorType } from '../models/pago.model';
import { MotivosService } from './motivos.service';
import { ProcedenciaService } from './procedencia.service';
import { VentaService } from './venta.service';
import { environment } from 'src/environments/pedrera/environment.development-pedrera';
import * as JSZip from 'jszip';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { getErrorMessage } from 'src/app/shared/enums/error-messages';

export interface Agrupados {
  tipoProducto: string;
  promoId: string;
  entradaId: string;
  posicionVenta: string;
  numEntrada: string;
  numPromoEntrada: string;
  codigoPromocional: string;
}

export type OpcionesImpresionQR = {
  itemsReimprimir?: string; // Definimos itemsReimprimir como opcional
  tpvId?: string;
  tpvImpresion?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PagoService {
  private pagoTotalSubject: BehaviorSubject<any>;
  public pagoTotal: Observable<any>;

  private operacionesTarjetaSubject: BehaviorSubject<any>;
  public operacionesTarjeta: Observable<any>;

  private pagoDesglosadoSubject: BehaviorSubject<any>;
  public pagoDesglosado: Observable<any>;

  private formularioCorrectoSubject: BehaviorSubject<Boolean>;
  public formularioCorrecto: Observable<Boolean>;

  private opcionesPagoSubject: BehaviorSubject<any>;
  public opcionesPago: Observable<any>;

  private cambioSubject: BehaviorSubject<any>;
  public cambio: Observable<any>;

  private diferenciaSubject: BehaviorSubject<any>;
  public diferencia: Observable<any>;

  private hayQueFacturarSubject: BehaviorSubject<any>;
  public hayQueFacturar: Observable<any>;

  private hayContactosSubject: BehaviorSubject<any>;
  public hayContactos: Observable<any>;

  private hayQueImprimirAlbaranSubject: BehaviorSubject<any>;
  public hayQueImprimirAlbaran: Observable<any>;

  private hayQueImprimirEtiquetasSubject: BehaviorSubject<any>;
  public hayQueImprimirEtiquetas: Observable<any>;

  // Yaribel 30102020  añadimos observable para el codigo de Tarjeta Monedero
  private codigoTarjetaMonederoSubject: BehaviorSubject<any>;
  public codigoTarjetaMonedero: Observable<any>;
  // Yaribel 30102020 fin de añadir observable

  //<<Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
  private hayDescuentoSubject: BehaviorSubject<boolean>;
  public hayDescuento$: Observable<boolean>;
  private descuentoObjetoSubject: BehaviorSubject<any>;
  public descuentoObjeto$: Observable<any>;
  //Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370 >>

  conf: any;
  idioma = 'es-ES';
  window = window as any;
  minDia;
  maxDia;
  datosTarjeta: GenerarNumAlbaranIdentificadorType;

  constructor(
    private userService: UserManagerService,
    private turnoService: TurnoService,
    private configuration: ConfigurationService,
    private venta: VentaService,
    private http: HttpClient,
    private config: AppConfiguration,
    private motivoService: MotivosService,
    private procedenciaService: ProcedenciaService,
    private toastService: ToastrService,
    private translate: TranslateService
  ) {
    this.pagoTotalSubject = new BehaviorSubject(undefined);
    this.pagoTotal = this.pagoTotalSubject.asObservable();

    this.cambioSubject = new BehaviorSubject(undefined);
    this.cambio = this.cambioSubject.asObservable();

    this.diferenciaSubject = new BehaviorSubject(undefined);
    this.diferencia = this.diferenciaSubject.asObservable();

    this.pagoDesglosadoSubject = new BehaviorSubject(undefined);
    this.pagoDesglosado = this.pagoDesglosadoSubject.asObservable();

    this.formularioCorrectoSubject = new BehaviorSubject(false);
    this.formularioCorrecto = this.pagoDesglosadoSubject.asObservable();

    this.opcionesPagoSubject = new BehaviorSubject(undefined);
    this.opcionesPago = this.opcionesPagoSubject.asObservable();

    this.hayQueFacturarSubject = new BehaviorSubject(false);
    this.hayQueFacturar = this.hayQueFacturarSubject.asObservable();

    this.hayQueImprimirAlbaranSubject = new BehaviorSubject(false);
    this.hayQueImprimirAlbaran =
      this.hayQueImprimirAlbaranSubject.asObservable();

    this.hayQueImprimirEtiquetasSubject = new BehaviorSubject(false);
    this.hayQueImprimirEtiquetas =
      this.hayQueImprimirEtiquetasSubject.asObservable();

    this.hayContactosSubject = new BehaviorSubject(false);
    this.hayQueFacturar = this.hayQueFacturarSubject.asObservable();

    this.operacionesTarjetaSubject = new BehaviorSubject(false);
    this.operacionesTarjeta = this.operacionesTarjetaSubject.asObservable();

    // Yaribel 30102020 inicializamos observable para el codigo de tarjeta monedero
    this.codigoTarjetaMonederoSubject = new BehaviorSubject(false);
    this.codigoTarjetaMonedero =
      this.codigoTarjetaMonederoSubject.asObservable();
    // Yaribel 30102020 finalizamos inicializacion de observable

    //<< Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
    this.hayDescuentoSubject = new BehaviorSubject(false);
    this.hayDescuento$ = this.hayDescuentoSubject.asObservable();
    this.descuentoObjetoSubject = new BehaviorSubject(undefined);
    this.descuentoObjeto$ = this.descuentoObjetoSubject.asObservable();
    // Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370 >>
  }

  // << Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370
  public get hayDescuentoValue() {
    return this.venta.carritoValue.hayDescuento;
  }

  public setHayDescuento(descuento) {
    this.venta.carritoValue.hayDescuento = descuento;
    this.hayDescuentoSubject.next(descuento);
  }
  public get descuentoObjetoValue() {
    return this.descuentoObjetoSubject.value;
  }

  public setDescuentoObjeto(descuento) {
    this.descuentoObjetoSubject.next(descuento);
  }
  // Yaribel 20210728 añadimos los observables para gestionar el descuento cliente mapfre VSTS 8370 >>

  // Yaribel 30102020 añadimos get y set para el codigo de tarjeta monedero
  public get codigoTarjetaMonederoValue() {
    return this.codigoTarjetaMonederoSubject.value;
  }

  setCodigoTarjetaMonederoValue(codigoTarjetaMonedero) {
    this.codigoTarjetaMonederoSubject.next(codigoTarjetaMonedero);
  }
  // Yaribel 30102020 fin de añadir get y set

  public get operacionesTarjetaValue() {
    return this.operacionesTarjetaSubject.value;
  }

  setOperacionesTarjetaValue(operacionesTarjeta) {
    this.operacionesTarjetaSubject.next(operacionesTarjeta);
  }

  public get opcionesPagoValue() {
    return this.opcionesPagoSubject.value;
  }

  setOpcionesPagoValue(opcionesPago) {
    this.opcionesPagoSubject.next(opcionesPago);
  }

  public get hayQueFacturarValue() {
    return this.hayQueFacturarSubject.value;
  }

  setHayQueFacturarValue(boolean) {
    this.hayQueFacturarSubject.next(boolean);
  }

  public get hayContactosValue() {
    return this.hayContactosSubject.value;
  }

  setHayContactosValue(boolean) {
    this.hayContactosSubject.next(boolean);
  }

  public get hayQueImprimirAlbaranValue() {
    return this.hayQueImprimirAlbaranSubject.value;
  }

  setHayQueImprimirEtiquetasValue(boolean) {
    this.hayQueImprimirEtiquetasSubject.next(boolean);
  }

  public get HayQueImprimirEtiquetasValue() {
    return this.hayQueImprimirEtiquetasSubject.value;
  }

  setHayQueImprimirAlbaranValue(boolean) {
    this.hayQueImprimirAlbaranSubject.next(boolean);
  }

  public get pagoDesglosadoValue() {
    return this.pagoDesglosadoSubject.value;
  }

  setPagoDesglosado(pago: any) {
    this.pagoDesglosadoSubject.next(pago);
  }

  public get formularioCorrectoValue() {
    return this.formularioCorrectoSubject.value;
  }

  setFormularioCorrecto(formulario: Boolean) {
    this.formularioCorrectoSubject.next(formulario);
  }

  resetPagoDesglosado() {
    const valores = {
      prepago: 0,
      transferencia: 0,
      pinpad: 0,
      datafono: 0,
      efectivo: 0,
      monedero: 0, // Yaribel 30102020 Añadimos monedero a pago desglosado
      aplazado: 0,
    };
    this.pagoDesglosadoSubject.next(valores);
  }

  public get pagoTotalValue() {
    return this.pagoTotalSubject.value;
  }

  setPagoTotal(pagoTotal) {
    this.pagoTotalSubject.next(pagoTotal);
  }

  public get cambioValue() {
    return this.cambioSubject.value;
  }

  setCambio(cambio) {
    this.cambioSubject.next(cambio);
  }

  public get diferenciaValue() {
    return this.diferenciaSubject.value;
  }

  setDiferencia(cambio) {
    this.diferenciaSubject.next(cambio);
  }

  esVentaCC() {
    return this.pagoDesglosadoValue.pinpad !== 0 ||
      this.pagoDesglosadoValue.datafono !== 0
      ? 1
      : 0;
  }

  calcularTotal() {
    let cantidadTotal = 0;
    this.venta.carritoValue.entradas.forEach((entrada) => {
      if (
        entrada.entrada.TipoProducto === '2' &&
        ('noEditable' in entrada.entrada ||
          entrada.entrada.noEditable === undefined)
      ) {
        entrada.entrada.PromocionEntradas.forEach((val) => {
          cantidadTotal =
            cantidadTotal +
            Math.floor(val.NumeroEntradas) * Math.floor(entrada.cantidad);
        });
      } else if (
        entrada.entrada.TipoProducto === '1' &&
        ('noEditable' in entrada.entrada ||
          entrada.entrada.noEditable === undefined)
      ) {
        cantidadTotal = cantidadTotal + Math.floor(entrada.cantidad);
      }
    });
    return cantidadTotal;
  }

  generarPeticionPago(entradaConjunta) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    this.conf = this.configuration.appConfig.value;
    const datosInicio = this.configuration.datosInicioValue;
    const test2 = this.generarMediosPagoVenta();
    const test =
      this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'
        ? this.generarMediosPagoVenta().filter(
            (fop) =>
              fop.Importe > 0 ||
              (fop.Importe == 0 &&
                fop.FOP === 'CA' &&
                this.venta.carritoValue.precioTotal -
                  this.venta.carritoValue.precioPrepago ==
                  0)
          )
        : // .slice(0, 1)
          this.generarMediosPagoVenta().filter(
            (fop) =>
              fop.Importe > 0 ||
              (fop.Importe == 0 &&
                fop.FOP === 'CA' &&
                this.venta.carritoValue.precioTotal -
                  this.venta.carritoValue.precioPrepago ==
                  0)
          );
    // console.log(test2, test)
    const body = {
      ConexionIacpos: conexionId,
      esVentaCC: this.esVentaCC(),
      NumAlbaran: this.esVentaCC() ? this.datosTarjeta.NumAlbaran : '',
      Identificador: this.esVentaCC() ? this.datosTarjeta.Identificador : '',
      contadorAlbaranes: {
        TpvId: this.turnoService.turnoValue.TpvId,
      },
      contadorTickets: {
        TpvId: this.turnoService.turnoValue.TpvId,
        NumEntradasAlbaran: this.calcularTotal(),
      },
      contadorOperacionesTarjeta: {
        TpvId: this.turnoService.turnoValue.TpvId,
      },
      CodigoCliente: this.venta.clienteSeleccionadoValue
        ? this.venta.clienteSeleccionadoValue.Codigo
        : '0',
      UsuarioID: this.turnoService.turnoValue.UsuarioId,
      TurnoId: this.turnoService.turnoValue.pkid,
      TPVId: this.turnoService.turnoValue.TpvId,
      TotalEntradas: this.calcularTotal(),
      PrecioBruto: this.venta.carritoValue.precioBase,
      DivisaId: this.turnoService.turnoValue.DivisaId,
      TieneDescuento:
        this.venta.clienteSeleccionadoValue &&
        this.venta.clienteSeleccionadoValue.ConDescuento === 'True'
          ? 1
          : 0,
      Descuento: this.venta.clienteSeleccionadoValue
        ? this.venta.clienteSeleccionadoValue.Dto
        : '',
      GenerarFactura: this.venta.clienteSeleccionadoValue
        ? this.venta.clienteSeleccionadoValue.FP_Facturar
        : '0',
      // cambiar formas de pago
      FOP: this.generarMediosPagoVenta().find(
        (mpv) =>
          mpv.Importe > 0 ||
          (mpv.Importe == 0 &&
            mpv.FOP === 'CA' &&
            this.venta.carritoValue.precioTotal -
              this.venta.carritoValue.precioPrepago ==
              0)
      ).FOP,
      // : this.venta.clienteSeleccionadoValue ? this.venta.clienteSeleccionadoValue.FP_FOP : 'CA',
      FechaHoraEntrada:
        this.venta.fechaSeleccionadaValue === ''
          ? ''
          : this.venta.carritoValue.FechaHoraAcceso,
      EntradasConMotivo: this.venta.carritoValue.hayDescuentoMotivo ? 1 : 0,
      SinFechaEntrada: this.venta.fechaSeleccionadaValue === '' ? 1 : 0,
      EsDevolucion: 0,
      TotalDescuento:
        this.venta.clienteSeleccionadoValue &&
        this.venta.clienteSeleccionadoValue.ConDescuento === 'True'
          ? this.venta.carritoValue.importeDescuentoCliente
          : '',
      FormaPagoId: this.venta.clienteSeleccionadoValue
        ? this.venta.clienteSeleccionadoValue.FP_Id
        : '1',
      TotalIVA: this.venta.carritoValue.ivaTotal,
      TotalPagar:
        //this.venta.carritoValue.precioTotal - this.venta.carritoValue.precioPrepago,
        this.venta.carritoValue.precioTotal, // Yaribel 20201221  modificamos la asignacion de total a pagar sin descontar prepago ya que se mete como una forma de pago mas.
      CambioRecibido:
        Number.parseFloat(
          this.generarMediosPagoVenta().find((mpv) => mpv.FOP === 'CA').Importe
        ) + Number.parseFloat(this.cambioValue),
      CambioDevuelto: Number.parseFloat(this.cambioValue),
      // SIEMPRE EUROS DE MOMENTO
      DivisaRecibidaId: datosInicio.DivisaRefId, // Divisa con lo que han pagado,
      DivisaDevueltaId: datosInicio.DivisaRefId, // Divisa devuelta
      NumAlbaranOrigen: 0,
      Preventa: 0,
      BonoTexto: '',
      GrupoTipoEntradaId: this.venta.carritoValue.entradas[0].entrada.GrupoId,
      Prefijo: this.venta.carritoValue.entradas[0].entrada.Prefijo,
      mediosPago:
        this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'
          ? this.generarMediosPagoVenta().filter(
              (fop) =>
                fop.Importe > 0 ||
                (fop.Importe == 0 &&
                  fop.FOP === 'CA' &&
                  this.venta.carritoValue.precioTotal -
                    this.venta.carritoValue.precioPrepago ==
                    0)
            )
          : //.slice(0, 1)
            this.generarMediosPagoVenta().filter(
              (fop) =>
                fop.Importe > 0 ||
                (fop.Importe == 0 &&
                  fop.FOP === 'CA' &&
                  this.venta.carritoValue.precioTotal -
                    this.venta.carritoValue.precioPrepago ==
                    0)
            ),
      entradasVendidas:
        this.venta.carritoValue.reservaAgrupada == '1'
          ? this.generarEntradasVendidasReservaAgrupada(entradaConjunta)
          : this.generarEntradasVendidas(entradaConjunta),
      // entradaConjunta
      //   ? this.generarEntradasVendidasConjunta()
      //   : this.generarEntradasVendidasIndividual(),
      recintosVenta: this.generarRecintosVenta(),
      aforosVenta: this.generarAforosVenta(),
      motivosgrupos: this.generarMotivosGrupos(),
      cajasTurno_cantidad: this.generarCajasTurnoCantidad(),
      resumenTurno: this.generarResumenTurno(),
      contactoId: 0,
      reservaVendida: this.venta.carritoValue.reserva,
      idMarcadorExterno: this.venta.idMarcadoExterno,
      operacionesTarjeta:
        this.esVentaCC() === 1 ? this.generarOperacionesTarjeta() : undefined,
      DescuentoClienteAsociadoDatos: this.hayDescuentoValue
        ? this.venta.carritoValue.descuentoClienteAsociadoDatos
        : '',
      GenerarQR: '1',
    };
    //
    //
    return body;
  }
  //cuando es pago multiple Operaciones Tarjeta solo debe coger lo de tarjeta
  generarOperacionesTarjeta() {
    const body = {
      FechaHora: moment().format('YYYY/MM/DD HH:mm:ss'),
      Importe: this.generarMediosPagoVenta().find((fop) => fop.FOP === 'CC')
        .Importe,
      //this.venta.carritoValue.precioTotal,
      TipoOperacion: '01',
      NumAutorizacion2oBloque: '',
      CodigoAceptacion: 'AC', // TODO control de otros codigos
      TextoInformativo: this.operacionesTarjetaValue.resultado,
      TextoReservadoControlador: this.operacionesTarjetaValue.codigoRespuesta,
      NumTarjeta: this.operacionesTarjetaValue.tarjetaClienteRecibo,
      FechaCaducidad: this.operacionesTarjetaValue.caducidad,
      NumAutorizacion1erBloque: '',
      FechaConciliacion1erBloque: '',
      FechaConciliacion2oBloque: '',

      TieneLector: this.pagoDesglosadoValue.datafono !== 0 ? '0' : '1',
      LecturaElectronica: this.pagoDesglosadoValue.datafono !== 0 ? '0' : '1',

      PresenciaTitular: '1',
      TarjetaPresente: '1',
      TipoTarjeta: this.operacionesTarjetaValue.etiquetaApp,
      NumOperacion: this.operacionesTarjetaValue.pedido,
      IdentEstablecimiento2oBloque: this.operacionesTarjetaValue.comercio,
      DatosAdicionales: this.operacionesTarjetaValue.identificadorRTS,
      DatosAdicionalesPrivados: this.operacionesTarjetaValue.terminal,
      CodigoAccion: '',
      PedidoBase: this.operacionesTarjetaValue.pedido,
      CodigoClienteID: '',
      NumReimpresiones: '1',
      IntroduccionManual: '0',
      DivisaId: this.turnoService.turnoValue.DivisaId,
    };
    return body;
  }

  pagar(entradaConjunta) {
    const body = this.generarPeticionPago(entradaConjunta);
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/VentaTaquilla/InsercionVenta`,
        body
      )
      .pipe(
        map((res: any) => {
          // ;
          if (!res.DatosResult) {
            this.toastService.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
            return res;
          }

          if (body.mediosPago.find((x) => x.FOP === 'CA')) this.abrirCajon();

          return {
            ...res.DatosResult,
            cambioDevuelto: body.CambioRecibido - body.CambioDevuelto,
            entradas: body.entradasVendidas.length,
          };
        })
      );
  }

  //JS 20201109
  marcarExterno(entradaConjunta) {
    const body = this.generarPeticionPago(entradaConjunta);
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/VentaTaquilla/MarcarVentaExterna`,
        body
      )
      .pipe(
        map((res: any) => {
          
          // ;
          if (!res || !res.DatosResult) {
            return res;
          }
          return res.DatosResult;
        })
      );
  }
  createToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    };
    const bodyToken = {
      usuario: environment.API_TOKEN_USER,
      contrasena: environment.API_TOKEN_PASSWORD,
    };
    return this.http
      .post(`${this.config.getConfig('API_URL_TAQUILLA')}/Token`, bodyToken, {
        headers: headers,
      })
      .pipe(
        map(async (res: any) => {
          if (!res.token) {
            this.toastService.error('', res.Mensajes[0].DescripcionMensaje, {
              toastComponent: ErrorToast,
              timeOut: 5000,
            });
            return res;
          }
          return res.token;
        })
      );
  }

  //JS 20191231 Hay que meter los campos de Promotor (MIREYA DOIT )
  /**
   * Genera y descarga los códigos QR y/o archivos PDF para imprimir entradas.
   * @param numAlbaran El número de albarán para identificar las entradas.
   * @param entradas La cantidad de entradas a imprimir.
   * @returns Una promesa que se resuelve con los códigos QR generados.
   */
  impresionQR(numAlbaran, entradas, opciones: OpcionesImpresionQR = {}) {
    return this.createToken().pipe(
      switchMap(async (token) => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam = opciones.tpvId
          ? `tpvId=${opciones.tpvId}`
          : `tpvId=${this.turnoService.turnoValue.TpvId}`;
        const numAlbaranParam = `numAlbaran=${numAlbaran}`;
        const plataformaParam = 'plataforma=2';
        const tpvIdImpresion = `tpvIdImpresion=${this.turnoService.turnoValue.TpvId}`;
        let paginaReimprimirResult = entradas > 20 ? 1 : 0;

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
        };
        let url = `${this.config.getConfig(
          'API_URL_TAQUILLA'
        )}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}&${tpvIdImpresion}&paginaReimprimir=${paginaReimprimirResult}`;
        if (opciones.itemsReimprimir) {
          url += `&itemsReimprimir=${opciones.itemsReimprimir}`;
        }
        let codigosQrs = [];
        let pdfData = [];
        let flag = true;
        while (flag) {
          const data = await this.getToPromise(url, headers);
            if (data && data.datos) {
              codigosQrs.push(data);
              pdfData.push(data.datos);
              try {
                if (this.canPrintPDFWithExternal()) {
                  this.window.external.imprimirPDFBinario(data.datos, 'ticket');
                }
              } catch (error) {
                return throwError(() => new Error('Error al imprimir con external'));
              }
              if (data.finImpresion) {
                flag = false;
                if (pdfData.length == 1 && !this.canPrintPDFWithExternal()) {
                  this.downloadPDF(data.datos, `${this.translate.instant('ticket')}.pdf`);
                }
                if (pdfData.length > 1 && !this.canPrintPDFWithExternal()) {
                  this.createZip(pdfData);
                }
              }
              paginaReimprimirResult += 1;
              url = `${this.config.getConfig(
                'API_URL_TAQUILLA'
              )}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}&${tpvIdImpresion}&paginaReimprimir=${paginaReimprimirResult}`;
            }
            if(data === null)
              flag = false;
        }
      }),
      catchError((error) => {
        const message = getErrorMessage(error.status);
        this.toastService.error('Error', `Ha ocurrido un error avise a su encargado`, {
          toastComponent: ErrorToast,
          timeOut: 5000,
        });
        return throwError(error); // O `of(null)` si deseas continuar sin propagar el error
      })
    );
  }
  /**
   * Método para imprimir un localizador junto con sus entradas.
   * @param localizador - El localizador que se imprimirá.
   * @param entradas - El número de entradas asociadas al localizador.
   * @returns Una promesa que se resuelve con los códigos QR generados para las entradas.
   */
  impresionLocalizador(localizador, entradas) {
    return this.createToken().pipe(
      switchMap(async (token) => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam = `tpvId=${this.turnoService.turnoValue.TpvId}`;
        const localizadorParam = `localizador=${localizador}`;
        const plataformaParam = 'plataforma=1';
        const usuarioBD = JSON.parse(localStorage.getItem('currentUser'));
        const tpvIdImpresion = `tpvIdImpresion=${this.turnoService.turnoValue.TpvId}`;
        let paginaReimprimirResult = entradas > 20 ? 1 : 0;

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
        };
        let url = `${this.config.getConfig(
          'API_URL_TAQUILLA'
        )}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${localizadorParam}&${plataformaParam}&${tpvIdImpresion}&paginaReimprimir=${paginaReimprimirResult}&usuarioBD=${usuarioBD}`;
        let codigosQrs = [];
        let pdfData = [];
        let flag = true;
        while (flag) {
          await this.getToPromise(url, headers).then((data) => {
            if (data && data.datos) {
              codigosQrs.push(data);
              pdfData.push(data.datos);
              if (this.canPrintPDFWithExternal())
                this.window.external.imprimirPDFBinario(data.datos, 'ticket');
              if (data.finImpresion) {
                flag = false;
                //Si el tamaño del pdfData es mayor a uno se descargara un .zip
                if (pdfData.length == 1 && !this.canPrintPDFWithExternal())
                  this.downloadPDF(
                    data.datos,
                    `${this.translate.instant('ticket')}.pdf`
                  );
                if (pdfData.length > 1 && !this.canPrintPDFWithExternal())
                  this.createZip(pdfData);
              }
              paginaReimprimirResult += 1;
              url = `${this.config.getConfig(
                'API_URL_TAQUILLA'
              )}/api/Ticket/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${localizadorParam}&${plataformaParam}&${tpvIdImpresion}&paginaReimprimir=${paginaReimprimirResult}&usuarioBD=${usuarioBD}`;
            }
            if(data === null)
              flag = false;
          });
        }
        return codigosQrs;
      }),
      catchError((error) => {
        this.toastService.error('Error', `Ha ocurrido un error avise a su encargado`, {
          toastComponent: ErrorToast,
          timeOut: 5000,
        });
        return throwError(error); // O `of(null)` si deseas continuar sin propagar el error
      })
    );
  }

  impresionJustificante(
    numAlbaran,
    nombreIdentificador,
    opciones: OpcionesImpresionQR = {}
  ) {
    return this.createToken().pipe(
      switchMap(async (token) => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam = opciones.tpvId
          ? `tpvId=${opciones.tpvId}`
          : `tpvId=${this.turnoService.turnoValue.TpvId}`;
        const numAlbaranParam = `numAlbaran=${numAlbaran}`;
        const plataformaParam = 'plataforma=2';
        //const usuarioId = `usuarioId=${this.turnoService.turnoValue.UsuarioId}`;
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
        };
        let url = `${this.config.getConfig(
          'API_URL_TAQUILLA'
        )}/api/Justificante/GenerarPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}`;
        if(opciones.tpvImpresion) {
          url += `&tpvIdImpresion=${opciones.tpvImpresion}`;
        }
        await this.getToPromise(url, headers).then((data) => {
          if (data && data.datos) {
              if (this.canPrintPDFWithExternal())
                this.window.external.imprimirPDFBinario(
                  data.datos,
                  "justificante"
                );
              if (!this.canPrintPDFWithExternal())
                this.downloadPDF(
                  data.datos,
                  `${nombreIdentificador}.pdf`
                );
          }
        });
      }),
      catchError((error) => {
        this.toastService.error('Error', `Ha ocurrido un error avise a su encargado`, {
          toastComponent: ErrorToast,
          timeOut: 5000,
        });
        return throwError(error);
      })
    );
  }

  /**
   * Crea un archivo ZIP que contiene archivos PDF a partir de una matriz de cadenas base64.
   * @param data Una matriz de cadenas base64 que representan los datos de los archivos PDF.
   * @returns Una promesa que se resuelve con el archivo ZIP generado.
   */
  async createZip(data: string[]) {
    const zip = new JSZip();
    data.forEach((base64, index) => {
      zip.file(
        `${this.translate.instant('ticket')}-${index + 1}.pdf`,
        this.createFilePdf(base64, 'application/pdf')
      );
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      // see FileSaver.js
      const blobUrl = URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'tickets.zip';
      link.click();
      URL.revokeObjectURL(blobUrl);
    });
  }

  /**
   * Realiza una solicitud HTTP GET y devuelve una promesa que se resuelve con los datos de la respuesta.
   * @param url La URL a la que se realizará la solicitud GET.
   * @param headers Los encabezados HTTP que se enviarán con la solicitud.
   * @returns Una promesa que se resuelve con los datos de la respuesta HTTP.
   */
  getToPromise(url: string, headers): Promise<any> {
    return this.http
      .get(url, { headers: headers, observe: 'response' }) // 'observe' para obtener la respuesta completa
      .pipe(
        map((res: any) => {
          if (!res) {
            throw new Error('Response undefined');
          }
          return res.body;
        }),
        catchError((error) => {
          const statusMatch = error.match(/Error:\s(\d+)/);
          const statusCode = statusMatch ? parseInt(statusMatch[1], 10) : null;
          const customError = {
            status: statusCode,
            message: error.message
          };
          return throwError(customError);
        })
      )
      .toPromise(); // Convertir el observable en una promesa
  }

  /**
   * Prints a PDF from a base64 string. If external printing is available,
   * it uses the external printing function; otherwise, it downloads the PDF.
   *
   * @param base64 - The base64 encoded string of the PDF.
   * @param fileName - The name of the file to be downloaded. Defaults to 'ticket.pdf'.
   */
  printPDFWithBase64(base64: string, fileName: string = 'ticket.pdf') {
    if (this.canPrintPDFWithExternal()) {
      this.window.external.imprimirPDFBinario(base64, 'ticket');
      //this.imprimirPDFBinario(base64);
    } else {
      this.downloadPDF(base64, fileName);
    }
  }

  /**
   * Creates a PDF file from a base64 string and triggers a download.
   *
   * @param base64 - The base64 encoded string of the PDF.
   * @param fileName - The name of the file to be downloaded.
   */
  downloadPDF(base64: string, fileName: string) {
    const blob = this.createFilePdf(base64, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(blobUrl);
  }

  /**
   * Crea un objeto Blob a partir de una cadena base64 y un tipo de archivo.
   * @param base64 La cadena en formato base64 que representa los datos del archivo.
   * @param type El tipo MIME del archivo, por ejemplo: 'application/pdf'.
   * @returns Un objeto Blob que representa el archivo generado.
   */
  createFilePdf(base64: string, type: string): Blob {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
    }
    return new Blob([arrayBuffer], { type });
  }

  /**
   * Checks if the external printing function is available.
   *
   * @returns A boolean indicating whether the external printing function is available.
   */
  canPrintPDFWithExternal(): boolean {
    return (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    );
  }

  /**
   * Encodes a base64 string to a binary string using window.btoa.
   *
   * @param base64 - The base64 encoded string.
   * @returns The binary string encoded from the base64 input.
   */
  imprimirPDFBinario(base64: string): string {
    return window.btoa(base64);
  }

  createTokenTarjeta() {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    };
    const bodyToken = {
      usuario: this.config.getConfig('API_TOKEN_USER'),
      contrasena: this.config.getConfig('API_TOKEN_PASSWORD'),
    };
    return this.http
      .post(`${this.config.getConfig('API_URL_TAQUILLA')}/Token`, bodyToken, {
        headers: headers,
      })
      .pipe(
        map(async (res: any) => {
          return res.token;
        })
      )
  }

  impresionTarjeta( nombreDocumento : string ) {
    return this.createTokenTarjeta().pipe(
      switchMap((token) => {
        const conexionId = JSON.parse(localStorage.getItem('conexionId'));
        const idiomaParam = 'idioma=es-ES';
        const tpvIdParam = `tpvId=${this.turnoService.turnoValue.TpvId}`;
        const numAlbaranParam = `numAlbaran=${this.datosTarjeta.NumAlbaran}`;
        const plataformaParam = 'plataforma=2';
        const usuarioId = `usuarioId=${this.configuration.datosTPVPathValue.pkId}`;

        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          Authorization: `Bearer ${token['__zone_symbol__value']}`,
        };

        // Retornamos la solicitud HTTP dentro del switchMap
        return this.http
          .get(
            `${this.config.getConfig(
              'API_URL_TAQUILLA'
            )}/api/Justificante/GenerarTarjetaPDF?conexionId=${conexionId}&${idiomaParam}&${tpvIdParam}&${numAlbaranParam}&${plataformaParam}`,
            { headers: headers, responseType: 'json' }
          )
          .pipe(
            map((response: { datos: string }) => response.datos),
            tap((pdfBase64) => {
              this.printPDFTarjeta(pdfBase64, nombreDocumento);
            })
          );
      }),
      catchError((error) => {
        const statusMatch = error.match(/Error:\s(\d+)/);
        const statusCode = statusMatch ? parseInt(statusMatch[1], 10) : null;

        // Crear un nuevo error con el código de estado y el mensaje original
        const customError = {
          status: statusCode,
          message: error.message
        };
        this.toastService.error('Error', `Ha ocurrido un error avise a su encargado`, {
          toastComponent: ErrorToast,
          timeOut: 5000,
        });
        return throwError(error); // O `of(null)` si deseas continuar sin propagar el error
      })
    )
  }

  printPDFTarjeta(base64: string, fileName: string = 'justificante.pdf') {
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(base64, 'justificante');
      //return window.btoa(base64);
    } else {
      const binaryData = atob(base64);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i) & 0xff;
      }

      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(blobUrl);
    }
  }

  private generarMediosPagoVenta(): any[] {
    return [
      {
        FOP: 'CC',
        Importe:
          this.pagoDesglosadoValue.pinpad + this.pagoDesglosadoValue.datafono,
      },
      {
        FOP: 'CA',
        Importe: (this.pagoDesglosadoValue.efectivo - this.cambioValue).toFixed(
          2
        ),
      },
      {
        FOP: 'TR',
        Importe: this.pagoDesglosadoValue.transferencia,
      },
      {
        FOP: 'PR', // Yaribel 20201217 modificamos la forma de pago IN por PR
        Importe: this.pagoDesglosadoValue.prepago,
      },
      // Yaribel 30102020 añadimos medio de pago TM
      {
        FOP: 'TM',
        Importe: this.pagoDesglosadoValue.monedero,
        TarjetaMonederoCodigo: this.codigoTarjetaMonederoValue,
      },
      // Yaribel 30102020 fin de añadir medio de pago
      {
        FOP: 'DP',
        Importe: this.pagoDesglosadoValue.aplazado,
      },
      {
        FOP: 'IN', // Yaribel 20201217 añadimos la forma de pago IN para la validación con las formas de pago de CajaTurno
        Importe: 0,
      },
    ];
  }

  private generarEntradasVendidas(entradaConjunta): any {
    let promocionNumOrden = 0;
    let contadorVisitantesHorarios = 0;
    let _this = this;
    const datosInicio = this.configuration.datosInicioValue;
    const entradas = [];
    const tipoEntradaIdAgrupadas: Agrupados[] = [];
    //para modificar con procedencias
    let procedencias = this.procedenciaService.getprocedenciasValue();
    let numProductoProcededncia = 0;
    let numEntradaProductoProcededncia = 0;
    let numEntradaCantidadProcededncia = 0;
    let totalProductosProcededncia = 1;
    let countEntrada = 0;
    this.venta.carritoValue.entradas.forEach((entrada) => {
      // ;
      // console.log('Entrada Generar Entradas Vendidas::', entrada);
      if (
        entrada.entrada.noEditable === undefined ||
        entrada.entrada.noEditable === null ||
        entrada.entrada.noEditable === false
      ) {
        if (entrada.entrada.TipoProducto === '2') {
          let contadorNominacion = 0;
          numEntradaProductoProcededncia = 0;
          for (let i = 0; i < entrada.cantidad; i++) {
            promocionNumOrden++;
            let precioPagado =
              Number.parseFloat(
                entrada.entrada.PrecioEnDivisaReferencia.replace(',', '.')
              ) - Number.parseFloat(entrada.entrada.Comision.replace(',', '.'));
            let nuevaEntrada = {
              PrecioPagado: precioPagado,
              DivisaId: datosInicio.DivisaRefId,
              NombreTipo: entrada.entrada.NombreTipo,
              TipoEntradaId: '',
              FechaHoraEntrada: '',
              Operacion: 'SA',
              BaseImponible: Number.parseFloat(
                entrada.entrada.PrecioBase.replace(',', '.')
              ),
              IVA: entrada.entrada.IVA,
              Devuelta: 0, // en venta siempre será 0,
              TipoAbreviado: '',
              AbonadoId: 0, // no aplica
              ClienteAsociadoId: 0, // no aplica
              OrdenCjta: entradaConjunta ? 1 : 0,
              Comision: Number.parseFloat(
                entrada.entrada.Comision.replace(',', '.')
              ), // campo comision
              IvaComision: Number.parseFloat(
                entrada.entrada.IvaComision.replace(',', '.')
              ), // campo iva comision,
              BonoTexto: '',
              DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
              Identificacion: '',
              CodigoPromocional: entrada.codigoPromocional,
              IdTarifa: entrada.entrada.idTarifa,
              GrupoTarifa: entrada.entrada.GrupoTarifa || '',
              PromocionId: entrada.entrada.TipoPromocionId,
              PromocionNombre: entrada.entrada.NombreTipo,
              PromocionNumOrden: promocionNumOrden,
              PosicionesVenta:
                entrada.entrada.PromocionEntradas &&
                entrada.entrada.PromocionEntradas[0].PosicionesVenta &&
                entrada.entrada.PromocionEntradas[0].PosicionesVenta[0]
                  ? entrada.entrada.PromocionEntradas[0].PosicionesVenta[0]
                      .PosicionVenta
                  : 0,
              TarjetaMonederoCodigo: '',
              EntradasVendidasPersonas: undefined,
              EntradasVendidasPersonasList: [],
              TipoProducto: entrada.entrada.TipoProducto,
            };
            entrada.entrada.PromocionEntradas.forEach((val) => {
              numEntradaCantidadProcededncia = 0;
              let tmpHora = '';
              if (
                val.CuentaVisitante &&
                val.CuentaVisitante == '1' &&
                this.estaenRecintoconHorarios(
                  val,
                  _this.venta.carritoValue.horarios
                )
              ) {
                //&& hay horario para este recinto
                //Yaribel 20210122 Modificamos para que obtenga el horario del recinto que le corresponde
                let horario =
                  _this.venta.carritoValue.horarios[
                    _this.venta.carritoValue.horarios.findIndex(
                      (item) =>
                        item.recinto.TipoEntrada === val.TipoEntradaId ||
                        (item.recinto.entradas != null &&
                          item.recinto.entradas.some(
                            (itemEntrada) => itemEntrada == val.TipoEntradaId
                          ))
                    )
                  ];
                // Yaribel 20210122
                tmpHora =
                  moment(horario.sesion.Fecha).format('YYYY/MM/DD') +
                  ' ' +
                  horario.sesion.HoraInicio +
                  ':00';
                if (
                  val.ArgumentosCaracteristica != null &&
                  val.ArgumentosCaracteristica.length != 0
                )
                  contadorVisitantesHorarios++;
              } else {
                tmpHora = val.FechaHoraAcceso;
              }
              // TODO ojo, verificar si la fecha viene como año/mes/dia, en el caso de forfait 2 horas es asi, por ejemplo
              for (let j = 0; j < +val.NumeroEntradas; j++) {
                //<< Yaribel 20210226 //<<Yaribel 20210226 Codigo para añadir posicionVenta añadimos la validacion de posicionVenta para los agrupados
                const miPosicionVenta =
                  val.PosicionesVenta && val.PosicionesVenta[0]
                    ? val.PosicionesVenta[0].PosicionVenta
                    : 0;
                //<< Yaribel 20210317 Codigo para añadir calculo de agrupadas con ImpresionTicket y ImprimirBarcode por entrada de la promocion
                let nEntrada = '0';
                let nPromoEntrada = '0';
                //modificamos nueva entrada para enviar entradas vendidas personas
                nuevaEntrada.FechaHoraEntrada = tmpHora;
                nuevaEntrada.TarjetaMonederoCodigo =
                  val.Caracteristicas !== undefined &&
                  val.Caracteristicas !== null &&
                  val.Caracteristicas.UsoMonedero === '1'
                    ? val.EntradasVendidasPersonas[contadorNominacion]
                        .TarjetaMonederoCodigo
                    : nuevaEntrada.TarjetaMonederoCodigo;
                //nuevaEntrada.EntradasVendidasPersonas =  this.cargarEntradasVendidasPersonas(entrada.entrada, val, contadorNominacion);
                let promocionEntrada = JSON.parse(JSON.stringify(nuevaEntrada));
                promocionEntrada.EntradasVendidasPersonas =
                  this.cargarEntradasVendidasPersonas(
                    entrada.entrada,
                    val,
                    contadorNominacion
                  );
                if (val.CuentaNominada == '1' || val.CuentaVisitante == '1') {
                  contadorNominacion++;
                }
                //Cargamos la procedencia si procede
                if (val.CuentaVisitante == '1') {
                  this.cargarProcedenciaEnEntrada(
                    promocionEntrada,
                    procedencias,
                    numProductoProcededncia,
                    numEntradaProductoProcededncia,
                    numEntradaCantidadProcededncia
                  );
                  // Actualizamos contadores
                  numEntradaCantidadProcededncia =
                    numEntradaCantidadProcededncia + 1;
                  totalProductosProcededncia = totalProductosProcededncia + 1;
                }

                if (
                  promocionEntrada.EntradasVendidasPersonas &&
                  promocionEntrada.EntradasVendidasPersonas !== ''
                )
                  nuevaEntrada.EntradasVendidasPersonasList.push({
                    TipoEntradaId: val.TipoEntradaId,
                    ...promocionEntrada.EntradasVendidasPersonas,
                  });
              }
              //this.generarRecintosVentaPorEntrada(entrada,nuevaEntrada, countEntrada, 0);
              this.generarRecintosVentaPorPromocion(
                entrada,
                nuevaEntrada,
                countEntrada,
                0
              );
              if (
                entrada.entrada.CaracteristicasEntrada != null &&
                entrada.entrada.CaracteristicasEntrada.length != 0 &&
                !(
                  entrada.entrada.PromocionEntradas != null &&
                  entrada.entrada.PromocionEntradas.length != 0
                )
              ) {
                contadorVisitantesHorarios++;
              }
            });
            entradas.push(nuevaEntrada);
            numEntradaProductoProcededncia++;
          }
        } else if (entrada.entrada.TipoProducto === '1') {
          //<<Yaribel 20210226 Codigo para añadir posicionVenta Inicializamos valores para PosicionVenta
          let miPosicionVenta = '0';
          let cantidadEntradas = 0;
          let indice = 0;
          numEntradaCantidadProcededncia = 0;
          //Yaribel 20210226 >>
          for (let i = 0; i < entrada.cantidad; i++) {
            //<<Yaribel 20210226 Codigo para añadir posicionVenta obtenemos la posicionventa de una entrada en concreto
            if (
              entrada.entrada.PosicionesVenta &&
              entrada.entrada.PosicionesVenta.length > 0 &&
              indice < entrada.entrada.PosicionesVenta.length
            ) {
              if (
                i >=
                entrada.entrada.PosicionesVenta[indice].length +
                  cantidadEntradas
              ) {
                miPosicionVenta =
                  entrada.entrada.PosicionesVenta[indice + 1].PosicionVenta;
                cantidadEntradas +=
                  entrada.entrada.PosicionesVenta[indice].length;
                indice += 1;
              }
            }
            //Yaribel 20210226 >>

            let tmpHora = '';
            if (
              entrada.entrada.CuentaVisitante &&
              entrada.entrada.CuentaVisitante == '1' &&
              this.estaenRecintoconHorarios(
                entrada.entrada,
                _this.venta.carritoValue.horarios
              )
            ) {
              //&& hay horario para este recinto
              //<< Yaribel 20210122 Modificamos para que obtenga el horario del recinto que le corresponde
              let horario =
                _this.venta.carritoValue.horarios[
                  _this.venta.carritoValue.horarios.findIndex(
                    (item) =>
                      item.recinto.TipoEntrada ===
                        entrada.entrada.TipoEntradaId ||
                      (item.recinto.entradas != null &&
                        item.recinto.entradas.some(
                          (itemEntrada) =>
                            itemEntrada == entrada.entrada.TipoEntradaId
                        ))
                  )
                ];
              // Yaribel 20210122 >>
              tmpHora =
                moment(horario.sesion.Fecha).format('YYYY/MM/DD') +
                ' ' +
                horario.sesion.HoraInicio +
                ':00';
              if (
                (entrada.entrada.ArgumentosCaracteristica != null &&
                  entrada.entrada.ArgumentosCaracteristica.length != 0) ||
                (entrada.entrada.CaracteristicasEntrada != null &&
                  entrada.entrada.CaracteristicasEntrada.length != 0)
              )
                contadorVisitantesHorarios++;
            } else {
              tmpHora = entrada.entrada.FechaHoraAcceso;
            }
            //<< Yaribel 20210317 Codigo para añadir calculo de agrupadas con ImpresionTicket y ImprimirBarcode
            const nEntrada =
              entrada.entrada.ImprimirTicket === '0' ? '' + i : '0';
            if (
              !tipoEntradaIdAgrupadas.find(
                (x) =>
                  x.posicionVenta == miPosicionVenta &&
                  x.tipoProducto == '1' &&
                  x.promoId == '0' &&
                  x.entradaId == entrada.entrada.TipoEntradaId &&
                  x.numEntrada == nEntrada &&
                  x.codigoPromocional == entrada.codigoPromocional
              )
            ) {
              tipoEntradaIdAgrupadas.push({
                promoId: '0',
                entradaId: entrada.entrada.TipoEntradaId,
                tipoProducto: '1',
                posicionVenta: miPosicionVenta,
                numEntrada: nEntrada,
                numPromoEntrada: '0',
                codigoPromocional: entrada.codigoPromocional,
              });
            }
            let precioPagado =
              Number.parseFloat(
                entrada.entrada.PrecioEnDivisaReferencia.replace(',', '.')
              ) - Number.parseFloat(entrada.entrada.Comision.replace(',', '.'));
            // Yaribel 20210317 >>
            const nuevaEntrada: any = {
              PrecioPagado: precioPagado,
              DivisaId: datosInicio.DivisaRefId,
              NombreTipo: entrada.entrada.NombreTipo,
              TipoEntradaId: entrada.entrada.TipoEntradaId,
              FechaHoraEntrada: moment(tmpHora).format('YYYY/MM/DD HH:mm:ss'), //entrada.entrada.Fecha === '' ? '' : moment(tmpHora).format('YYYY/MM/DD HH:mm:ss'),
              Operacion: 'SA',
              BaseImponible: Number.parseFloat(
                entrada.entrada.PrecioBase.replace(',', '.')
              ),
              IVA: entrada.entrada.IVA,
              Devuelta: 0, // en venta siempre será 0,
              TipoAbreviado: '',
              AbonadoId: 0, // no aplica
              ClienteAsociadoId: 0, // no aplica
              OrdenCjta: entradaConjunta ? 1 : 0, // Yaribel 20210121 Verificamos si es entrada conjunta para agrupar todas las entradas del mismo // Yaribel 20210226 Añadimos  PosicionVenta
              Comision: Number.parseFloat(
                entrada.entrada.Comision.replace(',', '.')
              ), // campo comision
              IvaComision: Number.parseFloat(
                entrada.entrada.IvaComision.replace(',', '.')
              ), // campo iva comision,
              BonoTexto: '',
              DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
              Identificacion: '',
              IdTarifa: entrada.entrada.idTarifa,
              GrupoTarifa: entrada.entrada.GrupoTarifa || '',
              CodigoPromocional: entrada.codigoPromocional,
              PosicionesVenta: entrada.entrada.PosicionesVenta,
              TarjetaMonederoCodigo:
                entrada.entrada.Caracteristicas !== undefined &&
                entrada.entrada.Caracteristicas !== null &&
                entrada.entrada.Caracteristicas.UsoMonedero === '1'
                  ? entrada.entrada.EntradasVendidasPersonas[i]
                    ? entrada.entrada.EntradasVendidasPersonas[i]
                        .TarjetaMonederoCodigo
                    : ''
                  : '',
              EntradasVendidasPersonas: undefined, // this.cargarEntradasVendidasPersonas(entrada.entrada, entrada.entrada, i),
              EntradasVendidasPersonasList: [],
              TipoProducto: entrada.entrada.TipoProducto,
            };
            let Entrada = JSON.parse(JSON.stringify(nuevaEntrada));
            Entrada.EntradasVendidasPersonas =
              this.cargarEntradasVendidasPersonas(
                entrada.entrada,
                entrada.entrada,
                i
              );
            //Cargamos la procedencia si procede
            if (entrada.entrada.CuentaVisitante == '1') {
              this.cargarProcedenciaEnEntrada(
                Entrada,
                procedencias,
                numProductoProcededncia,
                0,
                numEntradaCantidadProcededncia
              );
              // Actualizamos contadores
              numEntradaCantidadProcededncia =
                numEntradaCantidadProcededncia + 1;
              totalProductosProcededncia = totalProductosProcededncia + 1;
            }

            if (
              Entrada.EntradasVendidasPersonas &&
              Entrada.EntradasVendidasPersonas !== ''
            )
              nuevaEntrada.EntradasVendidasPersonasList.push({
                TipoEntradaId: entrada.entrada.TipoEntradaId,
                ...Entrada.EntradasVendidasPersonas,
              });

            // extraData solo lo tienen las butacas
            if (
              'extraData' in entrada.entrada &&
              entrada.entrada.extraData !== undefined &&
              entrada.entrada.extraData !== null
            ) {
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraEntrada
              ).format('YYYY/MM/DD HH:mm:ss');
              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId:
                  entrada.entrada.extraData.espectaculo.iEspectaculoId,
                RecintoId: entrada.entrada.extraData.dataIdRecinto,
                FechaHora: moment(entrada.entrada.FechaHoraEntrada).format(
                  'YYYY/MM/DD HH:mm:ss'
                ),
                Fila: entrada.entrada.extraData.dataNumFila,
                Butaca: entrada.entrada.extraData.dataNumButaca,
              };
            }
            // ListaButacas solo lo tienen las butacas recuperadas del localizador
            if (
              'ListaButacas' in entrada.entrada &&
              entrada.entrada.ListaButacas !== null &&
              entrada.entrada.ListaButacas.length > 0
            ) {
              //
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraAccesoLocalizadorButaca
              ).format('YYYY/MM/DD HH:mm:ss');

              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId: entrada.entrada.ListaButacas[i].IdEspectaculo,
                RecintoId: entrada.entrada.ListaButacas[i].IdRecinto,
                FechaHora: moment(
                  entrada.entrada.ListaButacas[i].FechaHora
                ).format('YYYY/MM/DD HH:mm:ss'),
                Fila: entrada.entrada.ListaButacas[i].NumFila,
                Butaca: entrada.entrada.ListaButacas[i].NumBuatca,
              };
            }
            this.generarRecintosVentaPorEntrada(
              entrada,
              nuevaEntrada,
              countEntrada,
              i
            );
            entradas.push(nuevaEntrada);
          }
        }
      }
      //Procedencias
      numProductoProcededncia = numProductoProcededncia + 1;
      countEntrada++;
    });
    return entradas;
  }

  private generarEntradasVendidasReservaAgrupada(entradaConjunta): any {
    let promocionNumOrden = 0;
    let contadorVisitantesHorarios = 0;
    let _this = this;
    const datosInicio = this.configuration.datosInicioValue;
    const entradas = [];
    const tipoEntradaIdAgrupadas: Agrupados[] = [];
    //para modificar con procedencias
    let procedencias = this.procedenciaService.getprocedenciasValue();
    let numProductoProcededncia = 0;
    let numEntradaProductoProcededncia = 0;
    let numEntradaCantidadProcededncia = 0;
    let totalProductosProcededncia = 1;
    let countEntrada = 0;
    this.venta.carritoValue.entradas.forEach((entrada) => {
      // ;
      // console.log('Entrada Generar Entrada Reserva Agrupada::', entrada)
      if (
        entrada.entrada.noEditable === undefined ||
        entrada.entrada.noEditable === null ||
        entrada.entrada.noEditable === false
      ) {
        if (entrada.entrada.TipoProducto === '2') {
          let contadorNominacion = 0;
          let arrayFarId = [];
          let arrayNumOrden = [];
          entrada.entrada.PosicionesVenta.forEach((posicion) => {
            posicion.NumOrden.forEach((num) => {
              arrayNumOrden.push(num);
            });
            posicion.FarId.forEach((farId) => {
              arrayFarId.push(farId);
            });
          });
          let arrayNumOrdenPromos = [];
          entrada.entrada.NumOrdenPromosConEntradas.forEach((num) => {
            arrayNumOrdenPromos.push(num.NumOrdenPromo);
          });

          //const arrayNumOrdenPromos = entrada.entrada.NumOrdenPromos;

          ////  Buscamos los datos en el localizador
          ////   el FarId, ReservaItems, PosicionVenta que le corresponde
          const indexEntrada =
            this.venta.carritoValue.localizador.ReservaItems.findIndex(
              (x) => x.TipoProducto == '2' && x.ItemId == entrada.entrada.ItemId
            );
          // Yaribel 20210629 >>
          let indexNumOrdenPromos = -1;
          numEntradaProductoProcededncia = 0;
          for (let i = 0; i < entrada.cantidad; i++) {
            promocionNumOrden++;
            indexNumOrdenPromos++;
            let miPosicionVenta = '0';
            let misFarIds = [];
            entrada.entrada.NumOrdenPromosConEntradas.forEach((x) => {
              if (x.NumOrdenPromo == arrayNumOrdenPromos[indexNumOrdenPromos]) {
                misFarIds.push(x.PromocionEntradas);
              }
            });
            miPosicionVenta =
              misFarIds.length > 0 &&
              misFarIds[0].FarIds &&
              misFarIds[0].FarIds[0]
                ? entrada.entrada.PosicionesVenta.find((x) =>
                    x.FarId.includes(misFarIds[0].FarIds[0])
                  ).PosicionVenta
                : 0;
            let precioPagado =
              Number.parseFloat(
                entrada.entrada.PrecioEnDivisaReferencia.replace(',', '.')
              ) - Number.parseFloat(entrada.entrada.Comision.replace(',', '.'));

            const nuevaEntrada = {
              PrecioPagado: precioPagado,
              DivisaId: datosInicio.DivisaRefId,
              NombreTipo: entrada.entrada.NombreTipo,
              TipoEntradaId: '',
              FechaHoraEntrada: '',
              Operacion: 'SA',
              BaseImponible: Number.parseFloat(
                entrada.entrada.PrecioBase.replace(',', '.')
              ),
              IVA: entrada.entrada.IVA,
              Devuelta: 0, // en venta siempre será 0,
              TipoAbreviado: '',
              AbonadoId: 0, // no aplica
              ClienteAsociadoId: 0, // no aplica
              OrdenCjta: entradaConjunta ? 1 : 0, // Yaribel 20210121 Verificamos si es entrada conjunta para agrupar todas las entradas del mismo  // Yaribel 20210223 Verificamos si es QR promo para agrupar todas las entradas de la promo
              Comision: Number.parseFloat(
                entrada.entrada.Comision.replace(',', '.')
              ), // campo comision
              IvaComision: Number.parseFloat(
                entrada.entrada.IvaComision.replace(',', '.')
              ), // campo iva comision,
              BonoTexto: '',
              DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
              Identificacion: '',
              CodigoPromocional: entrada.codigoPromocional,
              IdTarifa: entrada.entrada.idTarifa,
              GrupoTarifa: entrada.entrada.GrupoTarifa || '',
              PromocionId: entrada.entrada.TipoPromocionId,
              PromocionNombre: entrada.entrada.NombreTipo,
              PromocionNumOrden: arrayNumOrdenPromos[indexNumOrdenPromos],
              PosicionVenta: miPosicionVenta, //Yaribel 20210226 Codigo para añadir posicionVenta
              TarjetaMonederoCodigo: '',
              //EntradasVendidasPersonas: this.cargarEntradasVendidasPersonas(entrada.entrada, val, contadorNominacion)
              EntradasVendidasPersonas: undefined,
              EntradasVendidasPersonasList: [],
              TipoProducto: entrada.entrada.TipoProducto,
            };
            entrada.entrada.PromocionEntradas.forEach((val, index) => {
              //
              numEntradaCantidadProcededncia = 0;
              let tmpHora = '';
              let miFarId = 0;
              entrada.entrada.NumOrdenPromosConEntradas.forEach((x) => {
                if (
                  x.NumOrdenPromo == arrayNumOrdenPromos[indexNumOrdenPromos]
                ) {
                  miFarId = x.PromocionEntradas.find(
                    (promoEntrada) =>
                      promoEntrada.TipoEntradaId == val.TipoEntradaId
                  ).FarIds[0];
                }
              });
              if (
                val.CuentaVisitante &&
                val.CuentaVisitante == '1' &&
                this.estaenRecintoconHorarios(
                  val,
                  _this.venta.carritoValue.horarios
                )
              ) {
                //&& hay horario para este recinto
                let horario = this.venta.carritoValue.localizador
                  .ReservaRecintos[
                  this.venta.carritoValue.localizador.ReservaRecintos.findIndex(
                    (item) => item.idFilaAlbaranReserva == miFarId
                  )
                ]
                  ? this.venta.carritoValue.localizador.ReservaRecintos[
                      this.venta.carritoValue.localizador.ReservaRecintos.findIndex(
                        (item) => item.idFilaAlbaranReserva == miFarId
                      )
                    ]
                  : null;
                // Yaribel 20210122
                //tmpHora = moment(horario.sesion.Fecha).format('YYYY/MM/DD') + ' ' + horario.sesion.HoraInicio + ':00';
                tmpHora = horario ? horario.FechaEntrada : val.FechaHoraAcceso;
                //Prueba
                if (
                  val.ArgumentosCaracteristica != null &&
                  val.ArgumentosCaracteristica.length != 0
                )
                  contadorVisitantesHorarios++;
              } else {
                tmpHora = val.FechaHoraAcceso;
              }

              // TODO ojo, verificar si la fecha viene como año/mes/dia, en el caso de forfait 2 horas es asi, por ejemplo
              for (let j = 0; j < +val.NumeroEntradas; j++) {
                if (val.CuentaNominada == '1' || val.CuentaVisitante == '1') {
                  contadorNominacion++;
                }
                //Cargamos la procedencia si procede
                nuevaEntrada.FechaHoraEntrada = tmpHora;
                nuevaEntrada.TarjetaMonederoCodigo =
                  val.Caracteristicas !== undefined &&
                  val.Caracteristicas !== null &&
                  val.Caracteristicas.UsoMonedero === '1'
                    ? val.EntradasVendidasPersonas[contadorNominacion]
                        .TarjetaMonederoCodigo
                    : nuevaEntrada.TarjetaMonederoCodigo;
                //nuevaEntrada.EntradasVendidasPersonas =  this.cargarEntradasVendidasPersonas(entrada.entrada, val, contadorNominacion);
                let promocionEntrada = JSON.parse(JSON.stringify(nuevaEntrada));
                promocionEntrada.EntradasVendidasPersonas =
                  this.cargarEntradasVendidasPersonas(
                    entrada.entrada,
                    val,
                    contadorNominacion
                  );
                if (val.CuentaVisitante == '1') {
                  this.cargarProcedenciaEnEntrada(
                    promocionEntrada,
                    procedencias,
                    numProductoProcededncia,
                    numEntradaProductoProcededncia,
                    numEntradaCantidadProcededncia
                  );
                  // Actualizamos contadores
                  numEntradaCantidadProcededncia =
                    numEntradaCantidadProcededncia + 1;
                  totalProductosProcededncia = totalProductosProcededncia + 1;
                }

                if (
                  promocionEntrada.EntradasVendidasPersonas &&
                  promocionEntrada.EntradasVendidasPersonas !== ''
                )
                  nuevaEntrada.EntradasVendidasPersonasList.push({
                    TipoEntradaId: val.TipoEntradaId,
                    ...promocionEntrada.EntradasVendidasPersonas,
                  });
              }

              if (
                entrada.entrada.CaracteristicasEntrada != null &&
                entrada.entrada.CaracteristicasEntrada.length != 0 &&
                !(
                  entrada.entrada.PromocionEntradas != null &&
                  entrada.entrada.PromocionEntradas.length != 0
                )
              ) {
                contadorVisitantesHorarios++;
              }
            });

            this.generarRecintosVentaPorPromocion(
              entrada,
              nuevaEntrada,
              countEntrada,
              0
            );
            entradas.push(nuevaEntrada);
            numEntradaProductoProcededncia++;
          }
        } else if (entrada.entrada.TipoProducto === '1') {
          //<<Yaribel 20210226 Codigo para añadir posicionVenta Inicializamos valores para PosicionVenta
          let miPosicionVenta = '0';
          let cantidadEntradas = 0;
          let indice = 0;
          numEntradaCantidadProcededncia = 0;
          //Yaribel 20210226 >>
          //<< Yaribel 20210629 ya no buscamos los datos en el localizador lo buscamos en el objeto de posiciones venta
          let arrayFarId = [];
          let arrayNumOrden = [];
          entrada.entrada.PosicionesVenta.forEach((posicion) => {
            posicion.NumOrden.forEach((num) => {
              arrayNumOrden.push(num);
            });
            posicion.FarId.forEach((farId) => {
              arrayFarId.push(farId);
            });
          });

          for (let i = 0; i < entrada.cantidad; i++) {
            //<<Yaribel 20210629 Codigo para añadir posicionVenta
            entrada.entrada.PosicionesVenta.forEach((posicion) => {
              posicion.NumOrden.forEach((num) => {
                if (num == arrayNumOrden[i])
                  miPosicionVenta = posicion.PosicionVenta;
              });
            });
            let tmpHora = '';
            if (
              entrada.entrada.CuentaVisitante &&
              entrada.entrada.CuentaVisitante == '1' &&
              this.estaenRecintoconHorarios(
                entrada.entrada,
                _this.venta.carritoValue.horarios
              )
            ) {
              //&& hay horario para este recinto
              let horario =
                this.venta.carritoValue.localizador.ReservaRecintos[
                  this.venta.carritoValue.localizador.ReservaRecintos.findIndex(
                    (item) => item.idFilaAlbaranReserva == arrayFarId[i]
                  )
                ].FechaEntrada;
              tmpHora = horario;
              if (
                (entrada.entrada.ArgumentosCaracteristica != null &&
                  entrada.entrada.ArgumentosCaracteristica.length != 0) ||
                (entrada.entrada.CaracteristicasEntrada != null &&
                  entrada.entrada.CaracteristicasEntrada.length != 0)
              )
                contadorVisitantesHorarios++;
            } else {
              tmpHora = entrada.entrada.FechaHoraAcceso;
            }
            let precioPagado =
              Number.parseFloat(
                entrada.entrada.PrecioEnDivisaReferencia.replace(',', '.')
              ) - Number.parseFloat(entrada.entrada.Comision.replace(',', '.'));

            const nuevaEntrada: any = {
              PrecioPagado: precioPagado,
              DivisaId: datosInicio.DivisaRefId,
              NombreTipo: entrada.entrada.NombreTipo,
              TipoEntradaId: entrada.entrada.TipoEntradaId,
              FechaHoraEntrada: moment(tmpHora).format('YYYY/MM/DD HH:mm:ss'),
              Operacion: 'SA',
              BaseImponible: Number.parseFloat(
                entrada.entrada.PrecioBase.replace(',', '.')
              ),
              IVA: entrada.entrada.IVA,
              Devuelta: 0, // en venta siempre será 0,
              TipoAbreviado: '',
              AbonadoId: 0, // no aplica
              ClienteAsociadoId: 0, // no aplica
              OrdenCjta: entradaConjunta ? 1 : 0, // Yaribel 20210121 Verificamos si es entrada conjunta para agrupar todas las entradas del mismo // Yaribel 20210226 Añadimos  PosicionVenta
              Comision: Number.parseFloat(
                entrada.entrada.Comision.replace(',', '.')
              ), // campo comision
              IvaComision: Number.parseFloat(
                entrada.entrada.IvaComision.replace(',', '.')
              ), // campo iva comision,
              BonoTexto: '',
              DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
              Identificacion: '',
              IdTarifa: entrada.entrada.idTarifa,
              GrupoTarifa: entrada.entrada.GrupoTarifa || '',
              CodigoPromocional: entrada.codigoPromocional,
              PosicionVenta: miPosicionVenta,
              TarjetaMonederoCodigo:
                entrada.entrada.Caracteristicas !== undefined &&
                entrada.entrada.Caracteristicas !== null &&
                entrada.entrada.Caracteristicas.UsoMonedero === '1'
                  ? entrada.entrada.EntradasVendidasPersonas[i]
                    ? entrada.entrada.EntradasVendidasPersonas[i]
                        .TarjetaMonederoCodigo
                    : ''
                  : '',
              EntradasVendidasPersonas: undefined, //this.cargarEntradasVendidasPersonas(entrada.entrada, entrada.entrada, i),
              EntradasVendidasPersonasList: [],
              TipoProducto: entrada.entrada.TipoProducto,
            };
            let Entrada = JSON.parse(JSON.stringify(nuevaEntrada));
            Entrada.EntradasVendidasPersonas =
              this.cargarEntradasVendidasPersonas(
                entrada.entrada,
                entrada.entrada,
                i
              );
            //Cargamos la procedencia si procede
            if (entrada.entrada.CuentaVisitante == '1') {
              this.cargarProcedenciaEnEntrada(
                Entrada,
                procedencias,
                numProductoProcededncia,
                0,
                numEntradaCantidadProcededncia
              );
              // Actualizamos contadores
              numEntradaCantidadProcededncia =
                numEntradaCantidadProcededncia + 1;
              totalProductosProcededncia = totalProductosProcededncia + 1;
            }
            this.generarRecintosVentaPorEntrada(
              entrada,
              nuevaEntrada,
              countEntrada,
              i
            );

            // extraData solo lo tienen las butacas
            if (
              'extraData' in entrada.entrada &&
              entrada.entrada.extraData !== undefined &&
              entrada.entrada.extraData !== null
            ) {
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraEntrada
              ).format('YYYY/MM/DD HH:mm:ss');
              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId:
                  entrada.entrada.extraData.espectaculo.iEspectaculoId,
                RecintoId: entrada.entrada.extraData.dataIdRecinto,
                FechaHora: moment(entrada.entrada.FechaHoraEntrada).format(
                  'YYYY/MM/DD HH:mm:ss'
                ),
                Fila: entrada.entrada.extraData.dataNumFila,
                Butaca: entrada.entrada.extraData.dataNumButaca,
              };
            }
            // ListaButacas solo lo tienen las butacas recuperadas del localizador
            if (
              'ListaButacas' in entrada.entrada &&
              entrada.entrada.ListaButacas !== null &&
              entrada.entrada.ListaButacas.length > 0
            ) {
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraAccesoLocalizadorButaca
              ).format('YYYY/MM/DD HH:mm:ss');

              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId: entrada.entrada.ListaButacas[i].IdEspectaculo,
                RecintoId: entrada.entrada.ListaButacas[i].IdRecinto,
                FechaHora: moment(
                  entrada.entrada.ListaButacas[i].FechaHora
                ).format('YYYY/MM/DD HH:mm:ss'),
                Fila: entrada.entrada.ListaButacas[i].NumFila,
                Butaca: entrada.entrada.ListaButacas[i].NumBuatca,
              };
            }
            if (
              Entrada.EntradasVendidasPersonas &&
              Entrada.EntradasVendidasPersonas !== ''
            )
              nuevaEntrada.EntradasVendidasPersonasList.push(
                Entrada.EntradasVendidasPersonas
              );
            entradas.push(nuevaEntrada);
          }
        }
      }
      //Procedencias
      numProductoProcededncia = numProductoProcededncia + 1;
      countEntrada++;
    });
    //
    return entradas;
  }

  private generarEntradasVendidasIndividual(): any {
    let promocionNumOrden = 0;
    let contadorVisitantesHorarios = 0;
    let _this = this;
    const datosInicio = this.configuration.datosInicioValue;
    const entradas = [];
    //para modificar con procedencias
    let procedencias = this.procedenciaService.getprocedenciasValue();
    let numProductoProcededncia = 0;
    let numEntradaProductoProcededncia = 0;
    let numEntradaCantidadProcededncia = 0;
    let totalProductosProcededncia = 1;
    if (procedencias && procedencias.length > 0) {
    }
    this.venta.carritoValue.entradas.forEach((entrada) => {
      // ;
      if (
        entrada.entrada.noEditable === undefined ||
        entrada.entrada.noEditable === null ||
        entrada.entrada.noEditable === false
      ) {
        if (entrada.entrada.TipoProducto === '2') {
          let contadorNominacion = 0;

          for (let i = 0; i < entrada.cantidad; i++) {
            promocionNumOrden++;
            entrada.entrada.PromocionEntradas.forEach((val) => {
              //
              let tmpHora = '';
              if (
                val.CuentaVisitante &&
                val.CuentaVisitante == '1' &&
                this.estaenRecintoconHorarios(
                  val,
                  _this.venta.carritoValue.horarios
                )
              ) {
                //&& hay horario para este recinto
                //

                //<< Yaribel 20210122 Modificamos para que obtenga el horario del recinto que le corresponde
                //let horario = _this.venta.carritoValue.horarios[contadorVisitantesHorarios];
                let horario =
                  _this.venta.carritoValue.horarios[
                    _this.venta.carritoValue.horarios.findIndex(
                      (item) =>
                        item.recinto.TipoEntrada === val.TipoEntradaId ||
                        (item.recinto.entradas != null &&
                          item.recinto.entradas.some(
                            (itemEntrada) => itemEntrada == val.TipoEntradaId
                          ))
                    )
                  ];
                // Yaribel 20210122 >>
                tmpHora =
                  moment(horario.sesion.Fecha).format('YYYY/MM/DD') +
                  ' ' +
                  horario.sesion.HoraInicio +
                  ':00';
                //Prueba
                if (
                  val.ArgumentosCaracteristica != null &&
                  val.ArgumentosCaracteristica.length != 0
                )
                  contadorVisitantesHorarios++;
              } else {
                tmpHora = val.FechaHoraAcceso;
              }
              // TODO ojo, verificar si la fecha viene como año/mes/dia, en el caso de forfait 2 horas es asi, por ejemplo
              for (let j = 0; j < val.NumeroEntradas; j++) {
                const nuevaEntrada = {
                  PrecioPagado: Number.parseFloat(
                    val.PrecioEnDivisaReferencia
                      ? val.PrecioEnDivisaReferencia.replace(',', '.')
                      : val.PrecioTotal.replace(',', '.')
                  ),
                  DivisaId: datosInicio.DivisaRefId,
                  NombreTipo: val.NombreTipo,
                  TipoEntradaId: val.TipoEntradaId,
                  //FechaHoraEntrada: val.FechaHoraAcceso,
                  FechaHoraEntrada: tmpHora,
                  /* FechaHoraEntrada: moment(
                    val.FechaHoraAcceso,
                    'DD/MM/YYYY HH:mm:ss'
                  ).format('YYYY/MM/DD HH:mm:ss'), */
                  Operacion: 'SA',
                  BaseImponible: Number.parseFloat(
                    val.PrecioBase.replace(',', '.')
                  ),
                  IVA: val.IVA,
                  Devuelta: 0, // en venta siempre será 0,
                  TipoAbreviado: '',
                  AbonadoId: 0, // no aplica
                  ClienteAsociadoId: 0, // no aplica
                  OrdenCjta: 0, // no aplica
                  Comision: Number.parseFloat(
                    entrada.entrada.Comision.replace(',', '.')
                  ), // campo comision
                  IvaComision: Number.parseFloat(
                    entrada.entrada.IvaComision.replace(',', '.')
                  ), // campo iva comision,
                  BonoTexto: '',
                  DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
                  Identificacion: '',
                  CodigoPromocional: entrada.codigoPromocional,
                  IdTarifa: entrada.entrada.idTarifa,
                  PromocionId: entrada.entrada.TipoPromocionId,
                  PromocionNombre: entrada.entrada.NombreTipo,
                  PromocionNumOrden: promocionNumOrden,
                  TarjetaMonederoCodigo:
                    val.Caracteristicas !== undefined &&
                    val.Caracteristicas !== null &&
                    val.Caracteristicas.UsoMonedero === '1'
                      ? val.EntradasVendidasPersonas[contadorNominacion]
                          .TarjetaMonederoCodigo
                      : '',
                  EntradasVendidasPersonas: this.cargarEntradasVendidasPersonas(
                    entrada.entrada,
                    val,
                    contadorNominacion
                  ),
                  // val.EntradasVendidasPersonas !== undefined
                  // //? this.generarNominativas(val.EntradasVendidasPersonas[j])
                  // //? this.generarNominativas(val.EntradasVendidasPersonas.length > val.NumeroEntradas && entrada.cantidad > val.NumeroEntradas ? val.EntradasVendidasPersonas[i] : val.EntradasVendidasPersonas[j])
                  // ?  this.generarNominativas(val.EntradasVendidasPersonas[contadorNominacion]) //i+j
                  // : (entrada.entrada.FiltrosCaracteristicas && (val.CuentaNominada == "1" || val.CuentaVisitante =="1") ? this.generarNominativasxFiltroCaracteristica(entrada.entrada.FiltrosCaracteristicas[contadorNominacion]): '')
                  // //Controlamos como se mandan varios bonos como array dentro de las entradas, pero con la cantidad en el bono
                };
                if (val.CuentaNominada == '1' || val.CuentaVisitante == '1') {
                  contadorNominacion++;
                }
                //Cargamos la procedencia si procede
                if (val.CuentaVisitante == '1') {
                  this.cargarProcedenciaEnEntrada(
                    nuevaEntrada,
                    procedencias,
                    numProductoProcededncia,
                    numEntradaProductoProcededncia,
                    numEntradaCantidadProcededncia
                  );
                  // Actualizamos contadores
                  numEntradaCantidadProcededncia =
                    numEntradaCantidadProcededncia + 1;
                  totalProductosProcededncia = totalProductosProcededncia + 1;
                }
                //
                entradas.push(nuevaEntrada);
              }
              numEntradaProductoProcededncia++;
              if (
                entrada.entrada.CaracteristicasEntrada != null &&
                entrada.entrada.CaracteristicasEntrada.length != 0 &&
                !(
                  entrada.entrada.PromocionEntradas != null &&
                  entrada.entrada.PromocionEntradas.length != 0
                )
              ) {
                contadorVisitantesHorarios++;
              }
            });
          }
        } else if (entrada.entrada.TipoProducto === '1') {
          for (let i = 0; i < entrada.cantidad; i++) {
            //
            //   moment(entrada.entrada.FechaHoraAcceso).format(
            //     'YYYY/MM/DD HH:mm:ss'
            //   )
            // );
            let tmpHora = '';
            if (
              entrada.entrada.CuentaVisitante &&
              entrada.entrada.CuentaVisitante == '1' &&
              this.estaenRecintoconHorarios(
                entrada.entrada,
                _this.venta.carritoValue.horarios
              )
            ) {
              //&& hay horario para este recinto
              //
              //<< Yaribel 20210122 Modificamos para que obtenga el horario del recinto que le corresponde
              //let horario = _this.venta.carritoValue.horarios[contadorVisitantesHorarios];
              let horario =
                _this.venta.carritoValue.horarios[
                  _this.venta.carritoValue.horarios.findIndex(
                    (item) =>
                      item.recinto.TipoEntrada ===
                        entrada.entrada.TipoEntradaId ||
                      (item.recinto.entradas != null &&
                        item.recinto.entradas.some(
                          (itemEntrada) =>
                            itemEntrada == entrada.entrada.TipoEntradaId
                        ))
                  )
                ];
              // Yaribel 20210122 >>
              tmpHora =
                moment(horario.sesion.Fecha).format('YYYY/MM/DD') +
                ' ' +
                horario.sesion.HoraInicio +
                ':00';
              //Prueba
              if (
                (entrada.entrada.ArgumentosCaracteristica != null &&
                  entrada.entrada.ArgumentosCaracteristica.length != 0) ||
                (entrada.entrada.CaracteristicasEntrada != null &&
                  entrada.entrada.CaracteristicasEntrada.length != 0)
              )
                contadorVisitantesHorarios++;
            } else {
              tmpHora = entrada.entrada.FechaHoraAcceso;
            }
            const nuevaEntrada: any = {
              PrecioPagado: Number.parseFloat(
                entrada.entrada.PrecioEnDivisaReferencia.replace(',', '.')
              ),
              DivisaId: datosInicio.DivisaRefId,
              NombreTipo: entrada.entrada.NombreTipo,
              TipoEntradaId: entrada.entrada.TipoEntradaId,
              // FechaHoraEntrada: moment(entrada.entrada.FechaHoraAcceso).format(
              //   'YYYY/MM/DD HH:mm:ss'
              // ),
              FechaHoraEntrada: moment(tmpHora).format('YYYY/MM/DD HH:mm:ss'),
              Operacion: 'SA',
              BaseImponible: Number.parseFloat(
                entrada.entrada.PrecioBase.replace(',', '.')
              ),
              IVA: entrada.entrada.IVA,
              Devuelta: 0, // en venta siempre será 0,
              TipoAbreviado: '',
              AbonadoId: 0, // no aplica
              ClienteAsociadoId: 0, // no aplica
              OrdenCjta: 0, // no aplica
              Comision: Number.parseFloat(
                entrada.entrada.Comision.replace(',', '.')
              ), // campo comision
              IvaComision: Number.parseFloat(
                entrada.entrada.IvaComision.replace(',', '.')
              ), // campo iva comision,
              BonoTexto: '',
              DNIAbonadosExt: entrada.entrada.DNIAbonadosExt || '',
              Identificacion: '',
              IdTarifa: entrada.entrada.idTarifa,
              CodigoPromocional: entrada.codigoPromocional,
              TarjetaMonederoCodigo:
                entrada.entrada.Caracteristicas !== undefined &&
                entrada.entrada.Caracteristicas !== null &&
                entrada.entrada.Caracteristicas.UsoMonedero === '1'
                  ? entrada.entrada.EntradasVendidasPersonas[i]
                    ? entrada.entrada.EntradasVendidasPersonas[i]
                        .TarjetaMonederoCodigo
                    : ''
                  : '',
              EntradasVendidasPersonas: this.cargarEntradasVendidasPersonas(
                entrada.entrada,
                entrada.entrada,
                i
              ),
            };
            //Cargamos la procedencia si procede
            if (entrada.entrada.CuentaVisitante == '1') {
              this.cargarProcedenciaEnEntrada(
                nuevaEntrada,
                procedencias,
                numProductoProcededncia,
                0,
                numEntradaCantidadProcededncia
              );
              // Actualizamos contadores
              numEntradaCantidadProcededncia =
                numEntradaCantidadProcededncia + 1;
              totalProductosProcededncia = totalProductosProcededncia + 1;
            }
            //
            //
            // extraData solo lo tienen las butacas
            if (
              'extraData' in entrada.entrada &&
              entrada.entrada.extraData !== undefined &&
              entrada.entrada.extraData !== null
            ) {
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraEntrada
              ).format('YYYY/MM/DD HH:mm:ss');
              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId:
                  entrada.entrada.extraData.espectaculo.iEspectaculoId,
                RecintoId: entrada.entrada.extraData.dataIdRecinto,
                FechaHora: moment(entrada.entrada.FechaHoraEntrada).format(
                  'YYYY/MM/DD HH:mm:ss'
                ),
                Fila: entrada.entrada.extraData.dataNumFila,
                Butaca: entrada.entrada.extraData.dataNumButaca,
              };
            }
            // ListaButacas solo lo tienen las butacas recuperadas del localizador
            if (
              'ListaButacas' in entrada.entrada &&
              entrada.entrada.ListaButacas !== null &&
              entrada.entrada.ListaButacas.length > 0
            ) {
              //
              nuevaEntrada.FechaHoraEntrada = moment(
                entrada.entrada.FechaHoraAccesoLocalizadorButaca
              ).format('YYYY/MM/DD HH:mm:ss');

              nuevaEntrada.EntradasVendidasButacas = {
                EspectaculoId: entrada.entrada.ListaButacas[i].IdEspectaculo,
                RecintoId: entrada.entrada.ListaButacas[i].IdRecinto,
                FechaHora: moment(
                  entrada.entrada.ListaButacas[i].FechaHora
                ).format('YYYY/MM/DD HH:mm:ss'),
                Fila: entrada.entrada.ListaButacas[i].NumFila,
                Butaca: entrada.entrada.ListaButacas[i].NumBuatca,
              };
            }
            entradas.push(nuevaEntrada);
          }
        }
      }
      //Procedencias
      numProductoProcededncia = numProductoProcededncia + 1;
    });
    return entradas;
  }
  private generarEntradasVendidasConjunta() {
    const entradas = this.venta.carritoValue.entradas.map((linea, index) => {
      const nuevasEntradas = [];
      for (let i = 0; i < linea.cantidad; i++) {
        const nuevaEntrada = {
          PrecioPagado:
            Number.parseFloat(
              linea.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
            ) -
            (Number.parseFloat(linea.entrada.IVA) / 100) *
              Number.parseFloat(
                linea.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
              ),
          DivisaId: this.turnoService.turnoValue.DivisaId,
          NombreTipo: linea.entrada.NombreTipo,
          TipoEntradaId: linea.entrada.TipoEntradaId,
          // tslint:disable-next-line:max-line-length
          FechaHoraEntrada:
            moment(linea.entrada.Fecha).format('YYYY/MM/DD') +
            ' ' +
            this.venta.carritoValue.fecha.split(' ')[1],
          Operacion: 'SA',
          BaseImponible: linea.entrada.PrecioBase.replace(',', '.'),
          IVA: linea.entrada.IVA,
          Devuelta: 0, // en venta siempre será 0,
          TipoAbreviado: '',
          AbonadoId: 0, // no aplica
          ClienteAsociadoId: 0, // no aplica
          OrdenCjta: 1 + index, // no aplica
          Comision: linea.entrada.Comision, // campo comision
          IvaComision: linea.entrada.IvaComision, // campo iva comision,
          BonoTexto: '',
          DNIAbonadosExt: linea.entrada.DNIAbonadosExt || '',
          Identificacion: '',
        };
        if (linea.entrada.noEditable === null || !linea.entrada.noEditable) {
          nuevasEntradas.push(nuevaEntrada);
        }
      }
      //
      return nuevasEntradas;
    });
    return entradas.flat(1);
  }

  generarRecintosVenta(): any[] {
    let recintos = [];
    let countEntrada = 0;
    let conuntCaracteristica = 0;
    this.venta.carritoValue.entradas.forEach((entrada) => {
      if (
        entrada.entrada.noEditable === undefined ||
        entrada.entrada.noEditable === null ||
        entrada.entrada.noEditable === false
      ) {
        // ;
        // extraData solo lo tienen las butacas compradas en taquilla
        if (
          'extraData' in entrada.entrada &&
          entrada.entrada.extraData !== undefined &&
          entrada.entrada.extraData !== null
        ) {
          //
          recintos.push({
            TipoSesionId: entrada.entrada.extraData.espectaculo.idTipoSesion,
            TipoEntradaId: entrada.entrada.TipoEntradaId,
            recintoId: entrada.entrada.extraData.dataIdRecinto,
            fechaEntrada: moment(entrada.entrada.FechaHoraEntrada).format(
              'YYYY/MM/DD HH:mm:ss'
            ),
            EspectaculoId: entrada.entrada.extraData.espectaculo.iEspectaculoId,
          });
        }
        // ListaButacas solo lo tienen las butacas recuperadas del localizador
        if (
          'ListaButacas' in entrada.entrada &&
          entrada.entrada.ListaButacas !== null &&
          entrada.entrada.ListaButacas.length > 0
        ) {
          entrada.entrada.ListaButacas.forEach((butaca) => {
            recintos.push({
              TipoSesionId: butaca.IdTipoSesion,
              TipoEntradaId: entrada.entrada.TipoEntradaId,
              recintoId: butaca.IdRecinto,
              fechaEntrada: butaca.FechaHora,
              EspectaculoId: butaca.IdEspectaculo,
            });
          });
        }

        if (
          entrada.entrada.recintos !== undefined &&
          entrada.entrada.recintos !== null &&
          entrada.entrada.recintos.length > 0
        ) {
          entrada.entrada.recintos.forEach((recinto) => {
            if (recinto.EsActividad === '0' && recinto.ControlaAforo === '0') {
              recintos.push({
                TipoEntradaId: recinto.TipoEntrada,
                recintoId: recinto.Recinto,
                fechaEntrada: moment(entrada.entrada.FechaHoraAcceso).format(
                  'YYYY/MM/DD HH:mm:ss'
                ),
              });
            } else if (
              recinto.EsActividad === '0' &&
              recinto.ControlaAforo === '1'
            ) {
              if (this.venta.carritoValue.horarios.length > 0) {
                this.venta.carritoValue.horarios.forEach((item) => {
                  let existeEntrada;
                  if (item.recinto.entradas) {
                    existeEntrada = item.recinto.entradas.includes(
                      recinto.TipoEntrada
                    );
                  }
                  if (
                    item.recinto.Recinto === recinto.Recinto &&
                    existeEntrada
                  ) {
                    if (
                      entrada.entrada.CaracteristicasEntrada &&
                      entrada.entrada.CaracteristicasEntrada !== null &&
                      entrada.entrada.CaracteristicasEntrada.length > 0
                    ) {
                      if (
                        item.indiceEntrada == countEntrada &&
                        item.indiceCaracteristica == conuntCaracteristica
                      ) {
                        recintos.push({
                          TipoEntradaId: recinto.TipoEntrada,
                          recintoId: recinto.Recinto,
                          fechaEntrada:
                            moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                            ' ' +
                            item.sesion.HoraInicio +
                            ':00',
                          Guia:
                            item.GuiaSeleccionado &&
                            item.GuiaSeleccionado.iGuiaId
                              ? item.GuiaSeleccionado.iGuiaId
                              : '',
                          RecintosSesionesId: item.sesion.RecintosSesionId,
                          CaracteristicaId: item.indiceCaracteristica,
                        });
                        conuntCaracteristica++;
                        // if (conuntCaracteristica >= entrada.entrada.CaracteristicasEntrada.length)
                        // {
                        //   conuntCaracteristica = 0;
                        //   countEntrada++;
                        // }
                      }
                    } else {
                      recintos.push({
                        TipoEntradaId: recinto.TipoEntrada,
                        recintoId: recinto.Recinto,
                        fechaEntrada:
                          moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                          ' ' +
                          item.sesion.HoraInicio +
                          ':00',
                        Guia:
                          item.GuiaSeleccionado && item.GuiaSeleccionado.iGuiaId
                            ? item.GuiaSeleccionado.iGuiaId
                            : '',
                        RecintosSesionesId: item.sesion.RecintosSesionId,
                        CaracteristicaId: item.indiceCaracteristica,
                      });
                    }
                  }
                });
              }
            } else if (
              recinto.EsActividad === '1' &&
              recinto.ControlaAforo === '1'
            ) {
              if (this.venta.carritoValue.horarios.length > 0) {
                this.venta.carritoValue.horarios.forEach((item) => {
                  const existeEntrada = item.recinto.entradas.includes(
                    recinto.TipoEntrada
                  );
                  if (
                    item.recinto.Recinto === recinto.Recinto &&
                    existeEntrada
                  ) {
                    recintos.push({
                      TipoEntradaId: recinto.TipoEntrada,
                      recintoId: recinto.Recinto,
                      fechaEntrada:
                        moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                        ' ' +
                        item.sesion.HoraInicio +
                        ':00',
                    });
                  }
                });
              }
            }
          });
        }
      }
      conuntCaracteristica = 0;
      countEntrada++;
    });
    // quitamos duplicados
    recintos = recintos.filter(
      (recinto, index, self) =>
        index ===
        self.findIndex(
          (r) =>
            r.TipoEntradaId === recinto.TipoEntradaId &&
            r.recintoId === recinto.recintoId &&
            r.fechaEntrada === recinto.fechaEntrada &&
            r.Guia === recinto.Guia //&&
          //r.CaracteristicaId === recinto.CaracteristicaId
        )
    );

    /*     const fechasRecintos = [];
    recintos.forEach(recinto => {
      fechasRecintos.push(recinto.fechaEntrada);
    });
    const moments = fechasRecintos.map(d => moment(d, 'YYYY/MM/DD HH:mm:ss'));
    const fechaMinima = moment.min(moments).format('YYYY/MM/DD HH:mm:ss');
    
    this.venta.carritoValue.FechaHoraAcceso = fechaMinima; */
    return recintos;
  }

  private generarAforosVenta(): any {
    const res = [];
    this.venta.carritoValue.horarios.forEach((infoHorario) => {
      if (!infoHorario.EsButaca) {
        if (
          infoHorario.recinto.ControlaAforo !== '0' ||
          infoHorario.recinto.ControlaAforo !== null ||
          infoHorario.recinto.ControlaAforo !== undefined
        ) {
          res.push({
            RecintoId: infoHorario.recinto.Recinto,
            FechaHora:
              moment(infoHorario.sesion.Fecha).format('YYYY/MM/DD') +
              ' ' +
              infoHorario.sesion.HoraInicio +
              ':00',
            cantidad: infoHorario.cantidad,
          });
        }
      }
    });
    return res;
  }
  private generarMotivosGrupos(): any[] {
    const motivosArr = [];
    this.venta.carritoValue.entradas.forEach((entrada) => {
      // ;
      if (entrada.descuentos.length > 0) {
        if (entrada.entrada.TipoProducto == '1') {
          entrada.descuentos.forEach((desc) => {
            motivosArr.push({
              turnoId: this.turnoService.turnoValue.pkid,
              usuarioId: this.turnoService.turnoValue.UsuarioId,
              numMotivos: entrada.cantidad,
              itemId: desc.motivoDescuento.PkId,
              TipoEntradaId: entrada.entrada.TipoEntradaId,
            });
          });
        } else if (entrada.entrada.TipoProducto == '2') {
          entrada.entrada.PromocionEntradas.forEach((item) => {
            entrada.descuentos.forEach((desc) => {
              if (item.GrupoMotivoId === desc.motivoDescuento.GrupoId) {
                motivosArr.push({
                  turnoId: this.turnoService.turnoValue.pkid,
                  usuarioId: this.turnoService.turnoValue.UsuarioId,
                  numMotivos:
                    entrada.cantidad * parseInt(item.NumeroEntradas, 10),
                  itemId: desc.motivoDescuento.PkId,
                  TipoEntradaId: item.TipoEntradaId,
                });
              }
            });
          });
        }
      } else {
        //
        if (
          entrada.entrada.TipoEntradaId &&
          entrada.entrada.TipoEntradaId != '' &&
          entrada.entrada.TipoEntradaId != '0'
        ) {
          if (
            entrada.entrada.ListaMotivos &&
            entrada.entrada.ListaMotivos.length > 0
          ) {
            entrada.entrada.ListaMotivos.forEach((motivo) => {
              motivosArr.push({
                turnoId: this.turnoService.turnoValue.pkid,
                usuarioId: this.turnoService.turnoValue.UsuarioId,
                numMotivos: motivo.Cantidad,
                itemId: motivo.MotivoId,
                TipoEntradaId: entrada.entrada.TipoEntradaId,
              });
            });
          }
        } else if (
          entrada.entrada.TipoPromocionId &&
          entrada.entrada.TipoPromocionId != '' &&
          entrada.entrada.TipoPromocionId != '0'
        ) {
          entrada.entrada.PromocionEntradas.forEach((item) => {
            this.motivoService.motivosSeleccionadosValue.forEach((motivo) => {
              if (
                entrada.entrada.idTarifa == motivo.idEntrada &&
                item.GrupoMotivoId &&
                item.GrupoMotivoId != ''
              ) {
                motivo.lista.forEach((motivoItem) => {
                  motivosArr.push({
                    turnoId: this.turnoService.turnoValue.pkid,
                    usuarioId: this.turnoService.turnoValue.UsuarioId,
                    numMotivos:
                      motivoItem.numMotivo * parseInt(item.NumeroEntradas, 10),
                    itemId: motivoItem.PkId,
                    TipoEntradaId: item.TipoEntradaId,
                  });
                });
              }
            });
          });
        }
      }
    });
    return motivosArr;
  }

  private generarCajasTurnoCantidad(): any[] {
    return this.turnoService.cajasValue.map((caja) => {
      return {
        CajasTurnoId: caja.pkid,
        Cantidad: this.generarMediosPagoVenta().find(
          (medioDePago) => medioDePago.FOP === caja.MedioPago
        ).Importe,
      };
    });
  }

  private generarResumenTurno(): any {
    let totalEntradas = 0;
    this.venta.carritoValue.entradas.forEach((entrada) => {
      if (entrada.entrada.TipoProducto === '2') {
        entrada.entrada.PromocionEntradas.forEach((val) => {
          totalEntradas =
            totalEntradas + parseInt(val.NumeroEntradas, 10) * entrada.cantidad;
        });
      } else if (entrada.entrada.TipoProducto === '1') {
        totalEntradas = totalEntradas + parseInt(entrada.cantidad, 10);
      }
    });
    return {
      TurnoId: this.turnoService.turnoValue.pkid,
      UsuarioId: this.turnoService.turnoValue.UsuarioId,
      TPVId: this.turnoService.turnoValue.TpvId,
      NumEntradas: totalEntradas,
      IngresoAlbaranes: this.venta.carritoValue.precioTotal,
    };
  }

  generarPedidoTarjeta() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Plataforma: '2',
      Prefijo: '',
      TPVId: this.turnoService.turnoValue.TpvId,
      EsContingencia: '0',
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/VentaTaquilla/GenerarNumAlbaranIdentificador`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            this.toastService.error(
              'Error al generar el albarán de identificación',
              'Error de conexión',
              { toastComponent: ErrorToast }
            );
            return {};
          }
          this.datosTarjeta = res.DatosResult;
          return res.DatosResult;
        })
      );
  }

  datosCabecera() {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/Configuracion/GetCabeceraListados`,
        body
      )
      .pipe(
        map((res: any) => {
          if (res && res.DatosResult) return res.DatosResult;
        })
      );
  }

  carritoAplicarCodigoPromocional(carrito, codigoPromocional) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      carrito: carrito,
      CodigoPromocional: codigoPromocional,
    };
    return this.http
      .post(
        `${this.config.getConfig(
          'API_URL'
        )}api/VentaTaquilla/CarritoAplicarCodigoPromocional`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  generarFechaHoraEntrada() {}

  generarNominativas(formulario) {
    //
    return {
      idListaBlanca: null,
      nombre: formulario.nombre || null,
      apellido1: formulario.apellidos || null,
      apellido2: null,
      cifoDni: formulario.documento || null,
      domicilio: null,
      tipoCalle: null,
      numCalle: null,
      piso: null,
      localidad: null,
      cp: formulario.codigoPostal || '',
      provincia: null,
      pais: null,
      telefono: formulario.telefono || null,
      telefonoMovil: null,
      fax: null,
      email: formulario.email || null,
      fechaNacimiento: null,
      sexo: formulario.sexo || 'H',
      tipoCentro: formulario.Tipo
        ? formulario.Tipo || null
        : formulario.tipoCentro
        ? formulario.tipoCentro || null
        : formulario.tipoTabla || null, //tipoTabla
      edad: formulario.edad || null,
      rangoEdad: formulario.Rango
        ? formulario.Rango || null
        : formulario.rangoEdad || null,
      minusvalido: 0,
      jubilado: 0,
      publicidadInterna: 0,
      publicidadExterna: 0,
      nombreResponsable: null,
      apellido1Responsable: null,
      apellido2Responsable: null,
      nota: null,
      audioguia: 0,
      publicidadOrganizador: 0,
      curso: formulario.Nivel
        ? formulario.Nivel || null
        : formulario.curso || null,
      lse: 0,
      pregunta1: null,
      respuesta1: formulario.dia || null,
      pregunta2: null,
      respuesta2: formulario.trimestre || null,
      pregunta3: null,
      respuesta3: formulario.tipoDocumento || null,
      pregunta4: null,
      respuesta4: formulario.nidLocalizadorExterno
        ? formulario.nidLocalizadorExterno || null
        : formulario.respuesta4 || null,
      nombreCentro: null,
      nacionalidad: null,
      politicaPrivacidad: null,
      GuiaExterno: formulario.Profesor || null,
      idxEntrada: formulario.idxEntrada || null,
      idContacto: formulario.idContacto || null,
      CodigoAsignado: formulario.nidClienteExterno
        ? formulario.nidClienteExterno || null
        : formulario.CodigoAsignado || null,
      TarjetaMonederoCodigo: formulario.TarjetaMonederoCodigo || null,
    };
  }

  generarNominativasxFiltroCaracteristica(caracteristica) {
    //
    return {
      idListaBlanca: null,
      nombre: null,
      apellido1: null,
      apellido2: null,
      cifoDni: caracteristica.documento || null,
      domicilio: null,
      tipoCalle: null,
      numCalle: null,
      piso: null,
      localidad: null,
      cp: null,
      provincia: null,
      pais: null,
      telefono: null,
      telefonoMovil: null,
      fax: null,
      email: null,
      fechaNacimiento: null,
      sexo: null,
      tipoCentro: caracteristica.Tipo || null,
      edad: null,
      rangoEdad: caracteristica.Rango || null,
      minusvalido: 0,
      jubilado: 0,
      publicidadInterna: 0,
      publicidadExterna: 0,
      nombreResponsable: null,
      apellido1Responsable: null,
      apellido2Responsable: null,
      nota: null,
      audioguia: 0,
      publicidadOrganizador: 0,
      curso: caracteristica.Nivel || null,
      lse: 0,
      pregunta1: null,
      respuesta1: null,
      pregunta2: null,
      respuesta2: null,
      pregunta3: null,
      respuesta3: null,
      pregunta4: null,
      respuesta4: null,
      nombreCentro: null,
      nacionalidad: null,
      politicaPrivacidad: null,
      GuiaExterno: caracteristica.Profesor || null,
      idxEntrada: caracteristica.idxEntrada || null,
      idContacto: caracteristica.idContacto || null,
      CodigoAsignado: null,
    };
  }

  estaenRecintoconHorarios(entrada, horarios): boolean {
    let respuesta = false;
    //.log(horarios);
    //;
    if (
      horarios.findIndex(
        (item) =>
          item.recinto.TipoEntrada === entrada.TipoEntradaId ||
          (item.recinto.entradas != null &&
            item.recinto.entradas.some(
              (itemEntrada) => itemEntrada == entrada.TipoEntradaId
            ))
      ) >= 0
    ) {
      //
      respuesta = true;
    }
    return respuesta;
  }

  // Yaribel 03112020   Añadimos peticion al api para validar tarjeta monedero
  validarTarjetaMonedero(codigoTarjeta) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TarjetaMonederoCodigo: codigoTarjeta,
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/TarjetaMonederoEstado`,
        body
      )
      .pipe(
        map((res: any) => {
          // ;
          return res;
        })
      );
  }
  // Yaribel 11112020  Añadimos metodo para Asignar Entradas Vendidas
  cargarEntradasVendidasPersonas(entrada, formulario, contador) {
    if (entrada.TipoProducto === '2') {
      return formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null &&
        formulario.Caracteristicas.UsoMonedero === '1'
        ? ''
        : (formulario.Caracteristicas !== undefined &&
          formulario.Caracteristicas !== null
            ? formulario.Caracteristicas.UsoMonedero !== '1'
            : true) &&
          formulario.EntradasVendidasPersonas !== undefined &&
          formulario.EntradasVendidasPersonas !== null &&
          contador < formulario.EntradasVendidasPersonas.length
        ? this.generarNominativas(formulario.EntradasVendidasPersonas[contador])
        : entrada.FiltrosCaracteristicas &&
          entrada.FiltrosCaracteristicas !== undefined &&
          entrada.FiltrosCaracteristicas !== null &&
          contador < entrada.FiltrosCaracteristicas[contador] &&
          (formulario.CuentaNominada == '1' ||
            formulario.CuentaVisitante == '1')
        ? this.generarNominativasxFiltroCaracteristica(
            entrada.FiltrosCaracteristicas[contador]
          )
        : '';

      // val.EntradasVendidasPersonas !== undefined
      // //? this.generarNominativas(val.EntradasVendidasPersonas[j])
      // //? this.generarNominativas(val.EntradasVendidasPersonas.length > val.NumeroEntradas && entrada.cantidad > val.NumeroEntradas ? val.EntradasVendidasPersonas[i] : val.EntradasVendidasPersonas[j])
      // ?  this.generarNominativas(val.EntradasVendidasPersonas[contadorNominacion]) //i+j
      // : (entrada.entrada.FiltrosCaracteristicas && (val.CuentaNominada == "1" || val.CuentaVisitante =="1") ? this.generarNominativasxFiltroCaracteristica(entrada.entrada.FiltrosCaracteristicas[contadorNominacion]): '')
      // //Controlamos como se mandan varios bonos como array dentro de las entradas, pero con la cantidad en el bono
    } else if (entrada.TipoProducto === '1') {
      return formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null &&
        formulario.Caracteristicas.UsoMonedero === '1'
        ? ''
        : (formulario.Caracteristicas !== undefined &&
          formulario.Caracteristicas !== null
            ? formulario.Caracteristicas.UsoMonedero !== '1'
            : true) &&
          formulario.EntradasVendidasPersonas !== undefined &&
          formulario.EntradasVendidasPersonas !== null &&
          contador < formulario.EntradasVendidasPersonas.length
        ? this.generarNominativas(formulario.EntradasVendidasPersonas[contador])
        : formulario.FiltrosCaracteristicas &&
          entrada.FiltrosCaracteristicas !== undefined &&
          entrada.FiltrosCaracteristicas !== null &&
          contador < entrada.FiltrosCaracteristicas[contador]
        ? this.generarNominativasxFiltroCaracteristica(
            formulario.FiltrosCaracteristicas[contador]
          )
        : '';

      // entrada.entrada.EntradasVendidasPersonas !== undefined
      //   ? this.generarNominativas(
      //       entrada.entrada.EntradasVendidasPersonas[i]
      //     )
      //   : (entrada.entrada.FiltrosCaracteristicas ? this.generarNominativasxFiltroCaracteristica(entrada.entrada.FiltrosCaracteristicas[i]): '')
    }
  }
  cargarEntradasVendidasPersonasList(entrada, formulario, contador) {
    let list = [];
    if (entrada.TipoProducto === '2') {
      if (
        formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null &&
        formulario.Caracteristicas.UsoMonedero === '1'
      ) {
      } else if (
        (formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null
          ? formulario.Caracteristicas.UsoMonedero !== '1'
          : true) &&
        formulario.EntradasVendidasPersonas !== undefined &&
        formulario.EntradasVendidasPersonas !== null &&
        contador < formulario.EntradasVendidasPersonas.length
      ) {
        list.push(
          this.generarNominativas(formulario.EntradasVendidasPersonas[contador])
        );
      } else if (
        entrada.FiltrosCaracteristicas &&
        entrada.FiltrosCaracteristicas !== undefined &&
        entrada.FiltrosCaracteristicas !== null &&
        contador < entrada.FiltrosCaracteristicas[contador] &&
        (formulario.CuentaNominada == '1' || formulario.CuentaVisitante == '1')
      ) {
        list.push(
          this.generarNominativasxFiltroCaracteristica(
            entrada.FiltrosCaracteristicas[contador]
          )
        );
      }
    } else if (entrada.TipoProducto === '1') {
      if (
        formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null &&
        formulario.Caracteristicas.UsoMonedero === '1'
      ) {
      } else if (
        (formulario.Caracteristicas !== undefined &&
        formulario.Caracteristicas !== null
          ? formulario.Caracteristicas.UsoMonedero !== '1'
          : true) &&
        formulario.EntradasVendidasPersonas !== undefined &&
        formulario.EntradasVendidasPersonas !== null &&
        contador < formulario.EntradasVendidasPersonas.length
      ) {
        list.push(
          this.generarNominativas(formulario.EntradasVendidasPersonas[contador])
        );
      } else if (
        formulario.FiltrosCaracteristicas &&
        entrada.FiltrosCaracteristicas !== undefined &&
        entrada.FiltrosCaracteristicas !== null &&
        contador < entrada.FiltrosCaracteristicas[contador]
      ) {
        list.push(
          this.generarNominativasxFiltroCaracteristica(
            formulario.FiltrosCaracteristicas[contador]
          )
        );
      }
    }
    return list;
  }

  cargarProcedenciaEnEntrada(
    entrada,
    procedencias,
    contadorProducto,
    contadorEntradaProducto,
    contadorCantidad
  ) {
    let procedencia =
      procedencias && procedencias != null && procedencias != undefined
        ? procedencias.filter(
            (p) =>
              p.data.numeroProducto == contadorProducto &&
              p.data.numEntradaProducto == contadorEntradaProducto &&
              p.data.numEntradaCantidad == contadorCantidad
          )
        : [];
    if (procedencia.length > 0) {
      //&& (entrada && entrada.EntradasVendidasPersonas && entrada.EntradasVendidasPersonas != "" && entrada.EntradasVendidasPersonas.length == 1)
      if (entrada.EntradasVendidasPersonas == '') {
        entrada.EntradasVendidasPersonas = {};
      }
      if (procedencia[0].procedencias.pais) {
        entrada.EntradasVendidasPersonas.Pais =
          procedencia[0].procedencias.pais.NombrePais;
        entrada.EntradasVendidasPersonas.CodigoPais =
          procedencia[0].procedencias.pais.CodPais;
      }
      if (procedencia[0].procedencias.comunidad) {
        entrada.EntradasVendidasPersonas.CodComunidad =
          procedencia[0].procedencias.comunidad.CodComunidad;
      }
      if (
        procedencia[0].procedencias.ciudad &&
        procedencia[0].procedencias.ciudad != ''
      ) {
        if (typeof procedencia[0].procedencias.ciudad != 'string') {
          if (
            procedencia[0].procedencias.ciudad.CodCiudad &&
            procedencia[0].procedencias.ciudad.CodCiudad.trim() != ''
          ) {
            entrada.EntradasVendidasPersonas.CodCiudad =
              procedencia[0].procedencias.ciudad.CodCiudad;
            entrada.EntradasVendidasPersonas.Localidad =
              procedencia[0].procedencias.ciudad.Ciudad;
          } else {
            entrada.EntradasVendidasPersonas.CodProvincia =
              procedencia[0].procedencias.ciudad.CodProvincia;
            entrada.EntradasVendidasPersonas.Provincia =
              procedencia[0].procedencias.ciudad.NombreProvincia;
          }
        } else {
          entrada.EntradasVendidasPersonas.CP =
            procedencia[0].procedencias.ciudad;
        }
      }
    }
    return entrada;
  }

  //Generamos Recintos para cada entrada
  //entrada es la entrada del carrito
  //nuevaEntrada es el registro de entradasVendidas
  //indexEntrada es la posicion de las entradas individuales en el arrayFarId
  generarRecintosVentaPorEntrada(
    entrada,
    nuevaEntrada,
    countEntrada,
    indiceEntrada
  ) {
    let recintos = [];
    let conuntCaracteristica = 0;
    let misRecintos = [];
    let misHorarios = [];
    if (this.venta.carritoValue.reservaAgrupada == '1') {
      let TipoProducto = nuevaEntrada.PromocionId > 0 ? '2' : '1';
      const indexEntrada =
        this.venta.carritoValue.localizador.ReservaItems.findIndex(
          (x) =>
            x.TipoProducto == TipoProducto &&
            x.ItemId ==
              (TipoProducto == '2'
                ? nuevaEntrada.PromocionId
                : nuevaEntrada.TipoEntradaId)
        );
      // const arrayFarId = this.venta.carritoValue.localizador.ReservaItems[indexEntrada].FarId.split(',');
      let arrayFarId = [];
      entrada.entrada.PosicionesVenta.forEach((posicion) => {
        posicion.FarId.forEach((farId) => {
          arrayFarId.push(farId);
        });
      });
      let miFarId;
      if (TipoProducto == '2') {
        entrada.entrada.NumOrdenPromosConEntradas.forEach((x) => {
          if (x.NumOrdenPromo == nuevaEntrada.PromocionNumOrden) {
            miFarId = x.PromocionEntradas.find(
              (entrada) => entrada.TipoEntradaId == nuevaEntrada.TipoEntradaId
            ).FarIds[0];
          }
        });
        // const indexPromocionEntrada = this.venta.carritoValue.localizador.ReservaItems[indexEntrada].PromocionEntradas.findIndex(x=> x.NumOrdenPromo == nuevaEntrada.PromocionNumOrden && x.TipoEntradaId == nuevaEntrada.TipoEntradaId);
        // miFarId = this.venta.carritoValue.localizador.ReservaItems[indexEntrada].PromocionEntradas[indexPromocionEntrada].PosicionesVenta[0]?this.venta.carritoValue.localizador.ReservaItems[indexEntrada].PromocionEntradas[indexPromocionEntrada].PosicionesVenta[0].FarId[0]: '0';
      } else {
        miFarId = arrayFarId[indiceEntrada];
      }
      const misRecintosReserva =
        this.venta.carritoValue.localizador.ReservaRecintos.filter(
          (item) => item.idFilaAlbaranReserva == miFarId
        );

      if (entrada.entrada.recintos) {
        entrada.entrada.recintos.forEach((element) => {
          if (misRecintosReserva.find((x) => x.RecintoId == element.Recinto))
            misRecintos.push(element);
        });
      }
      misHorarios = this.mapHorarios(misRecintosReserva);
    } else {
      if (
        entrada.entrada.recintos &&
        entrada.entrada.recintos !== undefined &&
        entrada.entrada.recintos !== null &&
        entrada.entrada.recintos.length > 0
      ) {
        //misRecintos = entrada.entrada.recintos;
        entrada.entrada.recintos.forEach((element) => {
          if (element.entradas.find((x) => x == nuevaEntrada.TipoEntradaId))
            misRecintos.push(element);
        });
        misHorarios = this.venta.carritoValue.horarios;
      }
    }
    if (
      entrada.entrada.noEditable === undefined ||
      entrada.entrada.noEditable === null ||
      entrada.entrada.noEditable === false
    ) {
      // extraData solo lo tienen las butacas compradas en taquilla
      if (
        'extraData' in entrada.entrada &&
        entrada.entrada.extraData !== undefined &&
        entrada.entrada.extraData !== null
      ) {
        //
        recintos.push({
          TipoSesionId: entrada.entrada.extraData.espectaculo.idTipoSesion,
          TipoEntradaId: entrada.entrada.TipoEntradaId,
          recintoId: entrada.entrada.extraData.dataIdRecinto,
          fechaEntrada: moment(nuevaEntrada.FechaHoraEntrada).format(
            'YYYY/MM/DD HH:mm:ss'
          ),
          EspectaculoId: entrada.entrada.extraData.espectaculo.iEspectaculoId,
        });
      }
      // ListaButacas solo lo tienen las butacas recuperadas del localizador
      if (
        'ListaButacas' in entrada.entrada &&
        entrada.entrada.ListaButacas !== null &&
        entrada.entrada.ListaButacas.length > 0
      ) {
        entrada.entrada.ListaButacas.forEach((butaca) => {
          recintos.push({
            TipoSesionId: butaca.IdTipoSesion,
            TipoEntradaId: entrada.entrada.TipoEntradaId,
            recintoId: butaca.IdRecinto,
            fechaEntrada: butaca.FechaHora,
            EspectaculoId: butaca.IdEspectaculo,
          });
        });
      }

      if (
        misRecintos !== undefined &&
        misRecintos !== null &&
        misRecintos.length > 0
      ) {
        misRecintos.forEach((recinto) => {
          if (recinto.EsActividad === '0' && recinto.ControlaAforo === '0') {
            recintos.push({
              TipoEntradaId: recinto.TipoEntrada,
              recintoId: recinto.Recinto,
              fechaEntrada: moment(nuevaEntrada.FechaHoraEntrada).format(
                'YYYY/MM/DD HH:mm:ss'
              ),
            });
          } else if (
            recinto.EsActividad === '0' &&
            recinto.ControlaAforo === '1'
          ) {
            if (misHorarios.length > 0) {
              misHorarios.forEach((item) => {
                let existeEntrada;
                if (item.recinto.entradas) {
                  existeEntrada = item.recinto.entradas.includes(
                    recinto.TipoEntrada
                  );
                }
                if (item.recinto.Recinto === recinto.Recinto && existeEntrada) {
                  if (
                    entrada.entrada.CaracteristicasEntrada &&
                    entrada.entrada.CaracteristicasEntrada !== null &&
                    entrada.entrada.CaracteristicasEntrada.length > 0
                  ) {
                    if (
                      item.indiceEntrada == countEntrada &&
                      item.indiceCaracteristica == conuntCaracteristica &&
                      moment(
                        nuevaEntrada.FechaHoraEntrada,
                        'YYYY/MM/DD HH:mm:ss'
                      ).format('HH:mm') == item.sesion.HoraInicio
                    ) {
                      recintos.push({
                        TipoEntradaId: recinto.TipoEntrada,
                        recintoId: recinto.Recinto,
                        fechaEntrada:
                          moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                          ' ' +
                          item.sesion.HoraInicio +
                          ':00',
                        Guia:
                          item.GuiaSeleccionado && item.GuiaSeleccionado.iGuiaId
                            ? item.GuiaSeleccionado.iGuiaId
                            : '',
                        RecintosSesionesId: item.sesion.RecintosSesionId,
                        CaracteristicaId: item.indiceCaracteristica,
                      });
                      conuntCaracteristica++;
                    }
                  } else {
                    if (
                      moment(
                        nuevaEntrada.FechaHoraEntrada,
                        'YYYY/MM/DD HH:mm:ss'
                      ).format('HH:mm') == item.sesion.HoraInicio
                    )
                      recintos.push({
                        TipoEntradaId: recinto.TipoEntrada,
                        recintoId: recinto.Recinto,
                        fechaEntrada:
                          moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                          ' ' +
                          item.sesion.HoraInicio +
                          ':00',
                        Guia:
                          item.GuiaSeleccionado && item.GuiaSeleccionado.iGuiaId
                            ? item.GuiaSeleccionado.iGuiaId
                            : '',
                        RecintosSesionesId: item.sesion.RecintosSesionId,
                        CaracteristicaId: item.indiceCaracteristica,
                      });
                  }
                }
              });
            }
          } else if (
            recinto.EsActividad === '1' &&
            recinto.ControlaAforo === '1'
          ) {
            if (misHorarios.length > 0) {
              misHorarios.forEach((item) => {
                const existeEntrada = item.recinto.entradas.includes(
                  recinto.TipoEntrada
                );
                if (item.recinto.Recinto === recinto.Recinto && existeEntrada) {
                  if (
                    moment(
                      nuevaEntrada.FechaHoraEntrada,
                      'YYYY/MM/DD HH:mm:ss'
                    ).format('YYYY/MM/DD') ==
                      moment(item.sesion.Fecha).format('YYYY/MM/DD') &&
                    moment(
                      nuevaEntrada.FechaHoraEntrada,
                      'YYYY/MM/DD HH:mm:ss'
                    ).format('HH:mm') == item.sesion.HoraInicio
                  )
                    recintos.push({
                      TipoEntradaId: recinto.TipoEntrada,
                      recintoId: recinto.Recinto,
                      fechaEntrada:
                        moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                        ' ' +
                        item.sesion.HoraInicio +
                        ':00',
                    });
                }
              });
            }
          }
        });
      }
    }
    // quitamos duplicados
    recintos = recintos.filter(
      (recinto, index, self) =>
        index ===
        self.findIndex(
          (r) =>
            r.TipoEntradaId === recinto.TipoEntradaId &&
            r.recintoId === recinto.recintoId &&
            r.fechaEntrada === recinto.fechaEntrada &&
            r.Guia === recinto.Guia
        )
    );
    nuevaEntrada.recintosVenta = recintos;
    return nuevaEntrada;
  }

  generarRecintosVentaPorPromocion(
    entrada,
    nuevaEntrada,
    countEntrada,
    indiceEntrada
  ) {
    let recintos = [];
    let conuntCaracteristica = 0;
    let misRecintos = [];
    let misHorarios = [];
    let TipoProducto = nuevaEntrada.PromocionId > 0 ? '2' : '1';
    if (this.venta.carritoValue.reservaAgrupada == '1') {
      const indexEntrada =
        this.venta.carritoValue.localizador.ReservaItems.findIndex(
          (x) =>
            x.TipoProducto == TipoProducto &&
            x.ItemId ==
              (TipoProducto == '2'
                ? nuevaEntrada.PromocionId
                : nuevaEntrada.TipoEntradaId)
        );
      let arrayFarId = [];
      entrada.entrada.PosicionesVenta.forEach((posicion) => {
        posicion.FarId.forEach((farId) => {
          arrayFarId.push(farId);
        });
      });
      let miFarId = [];
      if (TipoProducto == '2') {
        entrada.entrada.NumOrdenPromosConEntradas.forEach((x) => {
          if (x.NumOrdenPromo == nuevaEntrada.PromocionNumOrden) {
            x.PromocionEntradas.forEach((element) => {
              miFarId.push(element.FarIds[0]);
            });
            //miFarId =  x.PromocionEntradas.find(entrada => entrada.TipoEntradaId == nuevaEntrada.TipoEntradaId).FarIds[0];
          }
        });
      } else {
        miFarId.push(arrayFarId[indiceEntrada]);
        //miFarId = arrayFarId[indiceEntrada];
      }

      let misRecintosReserva = [];
      miFarId.forEach((FarId) => {
        this.venta.carritoValue.localizador.ReservaRecintos.forEach(
          (recinto) => {
            if (recinto.idFilaAlbaranReserva == FarId) {
              misRecintosReserva.push(recinto);
            }
          }
        );
      });

      //const misRecintosReserva = this.venta.carritoValue.localizador.ReservaRecintos.filter((item) => item.idFilaAlbaranReserva == miFarId);

      entrada.entrada.recintos.forEach((element) => {
        if (misRecintosReserva.find((x) => x.RecintoId == element.Recinto))
          misRecintos.push(element);
      });
      misHorarios = this.mapHorarios(misRecintosReserva);
    } else {
      if (
        entrada.entrada.recintos &&
        entrada.entrada.recintos !== undefined &&
        entrada.entrada.recintos !== null &&
        entrada.entrada.recintos.length > 0
      ) {
        misRecintos = entrada.entrada.recintos;
        misHorarios = this.venta.carritoValue.horarios;
      }
    }
    if (
      entrada.entrada.noEditable === undefined ||
      entrada.entrada.noEditable === null ||
      entrada.entrada.noEditable === false
    ) {
      // extraData solo lo tienen las butacas compradas en taquilla
      if (
        'extraData' in entrada.entrada &&
        entrada.entrada.extraData !== undefined &&
        entrada.entrada.extraData !== null
      ) {
        //
        recintos.push({
          TipoSesionId: entrada.entrada.extraData.espectaculo.idTipoSesion,
          TipoEntradaId: entrada.entrada.TipoEntradaId,
          recintoId: entrada.entrada.extraData.dataIdRecinto,
          fechaEntrada: moment(nuevaEntrada.FechaHoraEntrada).format(
            'YYYY/MM/DD HH:mm:ss'
          ),
          EspectaculoId: entrada.entrada.extraData.espectaculo.iEspectaculoId,
        });
      }
      // ListaButacas solo lo tienen las butacas recuperadas del localizador
      if (
        'ListaButacas' in entrada.entrada &&
        entrada.entrada.ListaButacas !== null &&
        entrada.entrada.ListaButacas.length > 0
      ) {
        entrada.entrada.ListaButacas.forEach((butaca) => {
          recintos.push({
            TipoSesionId: butaca.IdTipoSesion,
            TipoEntradaId: entrada.entrada.TipoEntradaId,
            recintoId: butaca.IdRecinto,
            fechaEntrada: butaca.FechaHora,
            EspectaculoId: butaca.IdEspectaculo,
          });
        });
      }

      if (
        misRecintos !== undefined &&
        misRecintos !== null &&
        misRecintos.length > 0
      ) {
        misRecintos.forEach((recinto) => {
          if (recinto.EsActividad === '0' && recinto.ControlaAforo === '0') {
            recintos.push({
              TipoEntradaId: recinto.TipoEntrada,
              recintoId: recinto.Recinto,
              fechaEntrada: moment(nuevaEntrada.FechaHoraEntrada).format(
                'YYYY/MM/DD HH:mm:ss'
              ),
            });
          } else if (
            recinto.EsActividad === '0' &&
            recinto.ControlaAforo === '1'
          ) {
            if (misHorarios.length > 0) {
              misHorarios.forEach((item) => {
                const existeEntrada = item.recinto.entradas.includes(
                  recinto.TipoEntrada
                );
                if (item.recinto.Recinto === recinto.Recinto && existeEntrada) {
                  if (
                    entrada.entrada.CaracteristicasEntrada &&
                    entrada.entrada.CaracteristicasEntrada !== null &&
                    entrada.entrada.CaracteristicasEntrada.length > 0
                  ) {
                    if (
                      item.indiceEntrada == countEntrada &&
                      item.indiceCaracteristica == conuntCaracteristica
                    ) {
                      recintos.push({
                        TipoEntradaId: recinto.TipoEntrada,
                        recintoId: recinto.Recinto,
                        fechaEntrada:
                          moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                          ' ' +
                          item.sesion.HoraInicio +
                          ':00',
                        Guia:
                          item.GuiaSeleccionado && item.GuiaSeleccionado.iGuiaId
                            ? item.GuiaSeleccionado.iGuiaId
                            : '',
                        RecintosSesionesId: item.sesion.RecintosSesionId,
                        CaracteristicaId: item.indiceCaracteristica,
                      });
                      conuntCaracteristica++;
                    }
                  } else {
                    recintos.push({
                      TipoEntradaId: recinto.TipoEntrada,
                      recintoId: recinto.Recinto,
                      fechaEntrada:
                        moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                        ' ' +
                        item.sesion.HoraInicio +
                        ':00',
                      Guia:
                        item.GuiaSeleccionado && item.GuiaSeleccionado.iGuiaId
                          ? item.GuiaSeleccionado.iGuiaId
                          : '',
                      RecintosSesionesId: item.sesion.RecintosSesionId,
                      CaracteristicaId: item.indiceCaracteristica,
                    });
                  }
                }
              });
            }
          } else if (
            recinto.EsActividad === '1' &&
            recinto.ControlaAforo === '1'
          ) {
            if (misHorarios.length > 0) {
              misHorarios.forEach((item) => {
                const existeEntrada = item.recinto.entradas.includes(
                  recinto.TipoEntrada
                );
                if (item.recinto.Recinto === recinto.Recinto && existeEntrada) {
                  recintos.push({
                    TipoEntradaId: recinto.TipoEntrada,
                    recintoId: recinto.Recinto,
                    fechaEntrada:
                      moment(item.sesion.Fecha).format('YYYY/MM/DD') +
                      ' ' +
                      item.sesion.HoraInicio +
                      ':00',
                  });
                }
              });
            }
          }
        });
      }
    }
    // quitamos duplicados
    recintos = recintos.filter(
      (recinto, index, self) =>
        index ===
        self.findIndex(
          (r) =>
            r.TipoEntradaId === recinto.TipoEntradaId &&
            r.recintoId === recinto.recintoId &&
            r.fechaEntrada === recinto.fechaEntrada &&
            r.Guia === recinto.Guia
        )
    );
    nuevaEntrada.recintosVenta = recintos;
    return nuevaEntrada;
  }

  mapHorarios(recintos) {
    return this.venta.mapHorarios(recintos);
  }

  //Generamos la Orden Conjunta
  //entradaConjunta Variable que indica si se imprime o no Conjunta
  //entrada: datos de la entrada que vamos a evaluar
  //promocionEntrada: datos de la entrada de la promocion que evaluamos
  //miPosicionVenta: posicionVenta de la entrada que evaluamos (VentasAgrupadas)
  //tipoEntradaIdAgrupadas: array con las agrupaciones de las entradas
  generarOrdenConjunta(
    entradaConjunta,
    entrada,
    promocionEntrada,
    miPosicionVenta,
    tipoEntradaIdAgrupadas,
    CantidadEntrada,
    CantidadPromocionEntrada
  ) {
    let OrdenCjta;
    let OrdenCjtas;
    if (entradaConjunta) {
      if (entrada.entrada.TipoProducto === '1') {
        OrdenCjta =
          1 +
          tipoEntradaIdAgrupadas.indexOf(
            tipoEntradaIdAgrupadas.find(
              (x) =>
                x.posicionVenta == miPosicionVenta &&
                x.tipoProducto == '1' &&
                x.promoId == '0' &&
                x.entradaId == entrada.entrada.TipoEntradaId &&
                x.codigoPromocional == entrada.codigoPromocional
            )
          ); // Yaribel 20210121 Verificamos si es entrada conjunta para agrupar todas las entradas del mismo // Yaribel 20210226 Añadimos  PosicionVenta
      } else if (entrada.entrada.TipoProducto === '2') {
        OrdenCjta =
          1 +
          (entrada.entrada.ImprimirBarcode == '1'
            ? tipoEntradaIdAgrupadas.indexOf(
                tipoEntradaIdAgrupadas.find(
                  (x) =>
                    x.posicionVenta == miPosicionVenta &&
                    x.tipoProducto == '2' &&
                    x.promoId == entrada.entrada.TipoPromocionId &&
                    x.entradaId == '0' &&
                    x.codigoPromocional == entrada.codigoPromocional
                )
              )
            : tipoEntradaIdAgrupadas.indexOf(
                tipoEntradaIdAgrupadas.find(
                  (x) =>
                    x.posicionVenta == miPosicionVenta &&
                    x.tipoProducto == '2' &&
                    x.promoId == entrada.entrada.TipoPromocionId &&
                    x.entradaId == promocionEntrada.TipoEntradaId &&
                    x.codigoPromocional == entrada.codigoPromocional
                )
              )); // Yaribel 20210121 Verificamos si es entrada conjunta para agrupar todas las entradas del mismo  // Yaribel 20210223 Verificamos si es QR promo para agrupar todas las entradas de la promo
      }
    } else {
      OrdenCjta = 0;
    }
  }

  abrirCajon(): void {
    //llamar a Kore para que abra el cajon
    if (
      this.window.external &&
      typeof this.window.external.abrirCajon === 'function'
    ) {
      this.window.external.abrirCajon();
    } else {
    }
  }

  //<< Yaribel 20210728 cliente mapfre VSTS 8370
  //Yaribel 20210728 añadimos el calcular descuento de forma generica VSTS 8370
  calculoDescuento(productosDescuento) {
    const arrayProdDescuento = Object.values(productosDescuento);
    const prodCarrito = this.venta.carritoValue.entradas;
    const idsDescuento: any[] = arrayProdDescuento.map(
      (p: any) => p.ProductoId
    );
    const productos = [];
    idsDescuento.forEach((id) => {
      productos.push(prodCarrito.filter((p) => p.entrada.ItemId === id));
    });
    const productosFlat = productos.reduce((acc, val) => acc.concat(val), []);
    this.calcObjetoInsercion(arrayProdDescuento, productosFlat);
    let descuentoTotal = 0;
    let descuentoGrupo = 0;
    const ProductosAplicados = this.descuentoObjetoValue;
    ProductosAplicados.forEach((item) => {
      const prodDescuento: any = arrayProdDescuento.find(
        (p: any) =>
          p.ProductoId == item.ProductoId &&
          p.TipoProducto === item.TipoProducto
      );
      const precioInicial = Number.parseFloat(
        productosFlat
          .find(
            (x) =>
              x.entrada.TipoProducto === item.TipoProducto &&
              x.entrada.ItemId === item.ProductoId
          )
          .entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
      );
      const precioConDescuento = Number.parseFloat(
        prodDescuento.PrecioEnDivisaReferencia.replace(/,/, '.')
      );
      const descuentoPorEntrada = precioInicial - precioConDescuento;
      descuentoGrupo = descuentoPorEntrada * item.Cantidad;
      descuentoTotal = descuentoTotal + descuentoGrupo;
    });
    return descuentoTotal;
  }

  calcObjetoInsercion(arrayProdDescuento, productos) {
    const ProductosSeleccionados = [];
    productos.forEach((entrada) => {
      const prodDescuento: any = arrayProdDescuento.find(
        (p: any) =>
          p.ProductoId === entrada.entrada.ItemId &&
          p.TipoProducto === entrada.entrada.TipoProducto
      );
      const TipoProducto = prodDescuento.ProductosBono ? '2' : '1';
      const ProductoId = prodDescuento.ProductoId;
      const IdCategoria_GI = entrada.entrada.CategoriaId;
      let Cantidad;
      const CantidadAplicada = ProductosSeleccionados.find(
        (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
      )
        ? ProductosSeleccionados.find(
            (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
          ).Cantidad
        : 0;

      if (CantidadAplicada > 0) {
        const indice = ProductosSeleccionados.findIndex(
          (x) => x.TipoProducto === TipoProducto && x.ProductoId == ProductoId
        );

        if (
          parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada >=
          entrada.cantidad
        ) {
          ProductosSeleccionados[indice].Cantidad += entrada.cantidad;
        } else if (
          parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada >
          0
        ) {
          ProductosSeleccionados[indice].Cantidad +=
            parseInt(prodDescuento.MaximoTarifa, 10) - CantidadAplicada;
        }
      } else {
        if (parseInt(prodDescuento.MaximoTarifa, 10) >= entrada.cantidad) {
          Cantidad = entrada.cantidad;
        } else {
          Cantidad = parseInt(prodDescuento.MaximoTarifa, 10);
        }
        const prodFinal = {
          TipoProducto,
          ProductoId,
          Cantidad,
          IdCategoria_GI,
        };
        ProductosSeleccionados.push(prodFinal);
      }
    });
    this.setDescuentoObjeto(ProductosSeleccionados);
  }

  validarClienteAsociado(tipoDocumento, codigoCliente) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: this.idioma,
      Codigo: { TipoDocumento: tipoDocumento, NumeroDocumento: codigoCliente },
      Entradas: this.calcEntradas(),
      Bonos: this.calcBonos(),
      Plataforma: '2',
      // FechaDesde: moment().format('YYYY/MM/DD'),
      // FechaHasta: moment().add(1,'days').format('YYYY/MM/DD'),
      FechaDesde: this.minDia,
      FechaHasta: this.maxDia,
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/ValidarCompraClienteMapfre`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  calcEntradas() {
    const entradasSinBonos = this.venta.carritoValue.entradas.filter(
      (p) => p.entrada.TipoProducto == '1'
    );
    const entradas = [];
    entradasSinBonos.forEach((entrada) => {
      entradas.push({
        ProductoId: entrada.entrada.ItemId,
        IdCategoria_GI: entrada.entrada.CategoriaId,
        Cantidad: entrada.cantidad,
      });
    });
    const result = Array.from(
      entradas.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values()
    );
    return result;
  }

  calcBonos() {
    const entradasConBonos = this.venta.carritoValue.entradas.filter(
      (p) => p.entrada.TipoProducto == '2'
    );
    const bonos = [];
    entradasConBonos.forEach((entrada) => {
      bonos.push({
        ProductoId: entrada.entrada.ItemId,
        IdCategoria_GI: entrada.entrada.CategoriaId,
        Cantidad: entrada.cantidad,
      });
    });
    const result = Array.from(
      bonos.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values()
    );
    return result;
  }

  calcDiaMinimo() {
    this.minDia = '';
    this.venta.carritoValue.horarios.forEach((horario) => {
      if (horario.sesion.Fecha) {
        const compraDia = moment(horario.sesion.Fecha, 'YYYYMMDD').format(
          'YYYY/MM/DD'
        ); //moment(entrada.horarios.FechaHoraAcceso, 'YYYY/MM/DD HH:mm:ss');
        if (!this.minDia) {
          this.minDia = compraDia;
        } else {
          if (this.minDia > compraDia) {
            this.minDia = compraDia;
          }
        }
      } else {
        this.minDia = moment();
      }
    });
  }

  calcDiaMaximo() {
    this.maxDia = '';
    this.venta.carritoValue.horarios.forEach((horario) => {
      if (horario.sesion.Fecha) {
        const compraDia = moment(horario.sesion.Fecha, 'YYYYMMDD').format(
          'YYYY/MM/DD'
        );
        if (!this.maxDia) {
          this.maxDia = compraDia;
        } else {
          if (this.maxDia < compraDia) {
            this.maxDia = compraDia;
          }
        }
      } else {
        this.maxDia = moment();
      }
    });
  }

  setDescuentoCliente(
    Datos,
    importeDescuento,
    importeTotal,
    importeTotalConDescuento
  ) {
    this.venta.carritoValue.descuentoClienteAsociadoDatos = Datos;
    this.venta.carritoValue.importeDescuento = importeDescuento;
    this.venta.carritoValue.precioTotalSinDescuento = importeTotal;
  }
  // Yaribel 20210728 cliente mapfre VSTS 8370 >>

  calcDescuentoCliente() {
    const Datos = 0;
    return Datos;
  }

  consultarClienteAsociado(tipoDocumento, codigoCliente, fecha) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: this.idioma,
      Codigo: { TipoDocumento: tipoDocumento, NumeroDocumento: codigoCliente },
      Plataforma: '2',
      TPVId: this.turnoService.turnoValue.TpvId,
      FechaDesde: this.minDia == '' ? fecha || '' : this.minDia,
      FechaHasta: this.maxDia == '' ? fecha || '' : this.maxDia,
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/MapfreTarifasCategorias`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
